import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import {
  responsiveHeadline,
  headlineGradient,
  buttonStyles,
  NarrowContent,
  GradientBar
} from '../../components/Styled';
import { AboutSection, AboutPart, AboutRow } from './aboutStyles';

const About = () => {
  return (
    <AboutSection className="section about flex-center top-gradient">
      <NarrowContent className="about__content">
        <div>
          <header
            className={`story__header ${headlineGradient} ${responsiveHeadline}`}
          >
            <Translate id="about.header" />
          </header>
        </div>

        <AboutPart className="about__part about__part--one">
          <p>
            <Translate id="about.one.heading" />
          </p>
          <a className="about__link" href="mailto:info@milenariotequila.com">
            <Translate id="about.one.email" />
          </a>
          <AboutRow className="about__row">
            <div className="about__col">
              <p>
                <Translate id="about.one.body.left" />
              </p>
            </div>
            <div className="about__col">
              <p>
                <Translate id="about.one.body.right" />
              </p>
            </div>
            <GradientBar className="about__row--separator" />
          </AboutRow>
        </AboutPart>
        <AboutPart className="about__part about__part--two">
          <h4>
            <Translate id="about.two.heading" />
          </h4>
          <p>
            <Translate id="about.two.body" />
          </p>
          <Link
            className={`btn btn--primary btn--active ${buttonStyles}`}
            to="/legal"
          >
            <Translate id="about.two.link" />
          </Link>
        </AboutPart>
      </NarrowContent>
    </AboutSection>
  );
};

export default About;
