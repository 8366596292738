import React from 'react';
import cc from 'classcat';
import { Translate } from 'react-localize-redux';
import { headlineGradient } from '../../components/Styled';
import {
  TimelineBg,
  TimelineSection,
  TimelineHeader,
  TimelineBar,
  TimelineFact,
  TimelineFactTitle
} from './processStyles';

const PROCESS_STEPS = ['one', 'two', 'three', 'four', 'five', 'six'];

const Process = () => {
  return (
    <div className="timeline__sections">
      {PROCESS_STEPS.map((step, index) => (
        <TimelineSection
          key={step}
          className="timeline__section header-offset top-gradient bottom-gradient"
        >
          <TimelineBg
            className="timeline__background faded"
            style={{
              backgroundImage: `url(${require(`../../assets/img/timeline/background-${step}.jpg`)})`
            }}
          />
          {step === 'one' && (
            <div>
              <TimelineHeader
                className={`timeline__header ${headlineGradient}`}
              >
                <Translate id="process.header" />
              </TimelineHeader>
            </div>
          )}
          <div className="timeline__content">
            <TimelineFact
              marginTop={index * 2}
              className={cc([
                'timeline__fact',
                {
                  right: index % 2 === 0,
                  left: index % 2 === 1
                }
              ])}
            >
              <TimelineFactTitle className="timeline__fact--title">
                <Translate id={`process.fact.${step}.title`} />
              </TimelineFactTitle>
              <p className="timeline__fact--text">
                <Translate id={`process.fact.${step}.paragraph`} />
              </p>
            </TimelineFact>
            <TimelineBar className="timeline__bar" />
          </div>
        </TimelineSection>
      ))}
    </div>
  );
};

export default Process;
