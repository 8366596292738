import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import cc from 'classcat';
import {
  SliderContainer,
  SliderWrapper,
  SliderInner,
  SlidesList,
  SliderArrow,
  SliderFooter,
  SliderNavDot
} from './sliderStyles';

const SCROLL_THRESHOLD_TO_STOP_SCROLL = 30;

class Slider extends Component {
  static defaultProps = {
    infiniteLoop: true,
    selectedIndex: 0,
    showArrows: false,
    showNav: true,
    autoPlay: false,
    autoPlayInterval: 5000,
    pauseOnHover: true
  };

  state = {
    dragStart: 0,
    dragStartTime: new Date(),
    index: 0,
    lastIndex: 0,
    transition: false,
    hasMouseEntered: false
  };

  componentWillMount() {
    const { selectedIndex } = this.props;

    this.setState({
      index: selectedIndex,
      lastIndex: selectedIndex
    });
  }

  componentDidMount() {
    if (!this.props.children) {
      return;
    }

    if (this.props.autoPlay) {
      this.startAutoPlay();
    }
  }

  componentWillUnmount() {
    this.clearAutoPlay();
  }

  componentWillReceiveProps({ selectedIndex, autoPlay }) {
    if (selectedIndex !== this.props.selectedIndex) {
      this.goToSlide(selectedIndex);
    }

    if (autoPlay !== this.props.autoPlay) {
      if (autoPlay) {
        this.startAutoPlay();
      } else {
        this.clearAutoPlay();
      }
    }
  }

  getDragX(event, isTouch) {
    return isTouch ? event.touches[0].pageX : event.pageX;
  }

  handleDragStart = (event, isTouch = true) => {
    this.setState({
      dragStart: this.getDragX(event, isTouch),
      dragStartTime: new Date(),
      transition: false,
      slideWidth: ReactDOM.findDOMNode(this.sliderWrapper).offsetWidth
    });
  };

  handleDragMove = (event, isTouch = true) => {
    const { dragStart, lastIndex, slideWidth } = this.state;
    const offset = dragStart - this.getDragX(event, isTouch);
    const percentageOffset = offset / slideWidth;
    const newIndex = lastIndex + percentageOffset;

    // Stop scrolling when scrolling above the the threshold
    if (Math.abs(offset) > SCROLL_THRESHOLD_TO_STOP_SCROLL) {
      event.stopPropagation();
      event.preventDefault();
    }

    this.setState({ index: newIndex });
  };

  handleDragEnd = () => {
    const childrenLength = this.props.children.length;
    const { dragStartTime, index, lastIndex } = this.state;

    const timeElapsed = new Date().getTime() - dragStartTime.getTime();
    const offset = lastIndex - index;
    const velocity = Math.round((offset / timeElapsed) * 10000);

    let newIndex = Math.round(index);

    if (Math.abs(velocity) > 5) {
      newIndex = velocity < 0 ? lastIndex + 1 : lastIndex - 1;
    }

    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= childrenLength) {
      newIndex = childrenLength - 1;
    }

    this.setState({
      dragStart: 0,
      index: newIndex,
      lastIndex: newIndex,
      transition: true
    });
  };

  goToSlide(slideIndex, event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const { children, infiniteLoop } = this.props;

    let index = slideIndex;

    if (index < 0) {
      index = infiniteLoop ? children.length - 1 : 0;
    } else if (index >= children.length) {
      index = infiniteLoop ? 0 : children.length - 1;
    }

    this.setState({
      index,
      lastIndex: index,
      transition: true
    });

    if (this.props.onSlideIndexUpdate) {
      this.props.onSlideIndexUpdate(index)
    }

    // Reset current timer and continue auto-playing
    if (this.props.autoPlay && !this.state.hasMouseEntered) {
      this.autoPlay();
    }
  }

  goToNextSlide = () => {
    this.goToSlide(this.state.index + 1);
  };

  pauseOnMouseEnter = () => {
    this.setState({ hasMouseEntered: true });
    this.clearAutoPlay();
  };

  startOnMouseLeave = () => {
    this.setState({ hasMouseEntered: false });
    this.autoPlay();
  };

  clearAutoPlay() {
    if (!this.props.autoPlay) {
      return;
    }

    clearTimeout(this.autoPlayTimer);
  }

  startAutoPlay() {
    this.autoPlay();
    const sliderWrapperNode = this.sliderWrapper;

    if (this.props.pauseOnHover && sliderWrapperNode) {
      sliderWrapperNode.addEventListener('mouseenter', this.pauseOnMouseEnter);
      sliderWrapperNode.addEventListener('mouseleave', this.startOnMouseLeave);
    }
  }

  stopAutoPlay() {
    this.clearAutoPlay();
    const sliderWrapperNode = this.sliderWrapper;

    if (this.props.pauseOnHover && sliderWrapperNode) {
      sliderWrapperNode.removeEventListener(
        'mouseenter',
        this.pauseOnMouseEnter
      );
      sliderWrapperNode.removeEventListener(
        'mouseleave',
        this.startOnMouseLeave
      );
    }
  }

  autoPlay() {
    this.clearAutoPlay();

    this.autoPlayTimer = setTimeout(
      this.goToNextSlide,
      this.props.autoPlayInterval
    );
  }

  renderNavButton = (slide, currentIndex) => {
    const { lastIndex } = this.state;
    const navButton = (
      <SliderNavDot
        key={currentIndex}
        className={cc({ 'slider__dot--active': currentIndex === lastIndex })}
        onClick={(e) => this.goToSlide(currentIndex, e)}
      />
    );

    if (this.props.renderNavButton) {
      return this.props.renderNavButton({
        navButton,
        currentIndex,
        lastIndex
      });
    }

    return navButton;
  };

  renderArrows() {
    const { children, infiniteLoop, arrowsClassName } = this.props;
    const { lastIndex } = this.state;

    return (
      <div className={cc(['slider__arrows', arrowsClassName])}>
        {(infiniteLoop || lastIndex > 0) && (
          <SliderArrow
            className="slider__arrow slider__arrow--left"
            onClick={(e) => this.goToSlide(lastIndex - 1, e)}
          />
        )}
        {(infiniteLoop || lastIndex < children.length - 1) && (
          <SliderArrow
            className="slider__arrow slider__arrow--right"
            onClick={(e) => this.goToSlide(lastIndex + 1, e)}
          />
        )}
      </div>
    );
  }

  render() {
    const { children, showArrows, showNav } = this.props;
    const { index } = this.state;

    const slidesStyles = {
      width: `${100 * children.length}%`,
      transform: `translate3d(${-1 * index * (100 / children.length)}%, 0, 0)`
    };

    return (
      <SliderWrapper
        className={cc(["slider", this.props.className])}
        style={this.props.style}
        innerRef={(i) => {
          this.sliderWrapper = i;
        }}
      >
        <SliderContainer className="slider__container">
          {showArrows && children.length > 1 && this.renderArrows()}
          <SliderInner
            className="slider__inner"
            onTouchStart={this.handleDragStart}
            onTouchMove={this.handleDragMove}
            onTouchEnd={this.handleDragEnd}
          >
            <SlidesList className="slider__list" style={slidesStyles}>{children}</SlidesList>
          </SliderInner>
        </SliderContainer>
        {showNav &&
          children.length > 1 && (
            <SliderFooter className="slider__footer">
              {children.map(this.renderNavButton)}
            </SliderFooter>
          )}
      </SliderWrapper>
    );
  }
}

Slider.propTypes = {
  children: PropTypes.node,
  infiniteLoop: PropTypes.bool,
  selectedIndex: PropTypes.number,
  showArrows: PropTypes.bool,
  showNav: PropTypes.bool,
  renderNavButton: PropTypes.func,
  autoPlay: PropTypes.bool,
  autoPlayInterval: PropTypes.number,
  arrowsClassName: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  onSlideIndexUpdate: PropTypes.func
};

export default Slider;
