import React from 'react';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { Translate } from 'react-localize-redux';
import Fade from 'react-reveal/Fade';
import LanguageToggle from '../LanguageToggle';
import FlexibleLink from '../FlexibleLink';
import Icon from '../Icon';
import {
  AgeCheckIcons,
  AgeCheckForm,
  AgeCheckContainer,
  inputStyles
} from './ageCheckStyles';
import { Button } from '../Styled';
import LogoImg from '../../assets/img/global/logo.png';

const ICONS = ['blanco', 'reposado', 'anejo', 'extra-anejo', 'cafe', 'fumado'];

const isOfLegalAge = ({ day, month, year }) => {
  return new Date(parseInt(year, 10) + 18, month - 1, day) <= new Date();
};

const validateDate = (str) => {
  const m = str.match(/^(\d{1,2}) \| (\d{1,2}) \| (\d{4})$/);

  return m ? { year: m[3], month: m[2], day: m[1] } : null;
};

class AgeCheck extends React.Component {
  state = {
    birthdate: '',
    isInvalid: false
  };

  static defaultProps = {
    onSuccess: () => {}
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = (event) => {
    event.preventDefault();

    this.setState({ isInvalid: false }, () => {
      const date = validateDate(this.state.birthdate);

      if (!date || !isOfLegalAge(date)) {
        return this.setState({ isInvalid: true });
      } else {
        this.props.onSuccess();
      }
    });
  };

  render() {
    return (
      <AgeCheckContainer className="ageCheck">
        <div className="ageCheck__inner">
          <LanguageToggle className="lang__toggle btn btn--primary" />
          <img
            className="ageCheck__logo"
            src={LogoImg}
            alt="Sierra Milenario"
          />
          <div className="ageCheck__content p-center">
            <AgeCheckForm onSubmit={this.onSubmit} className="ageCheck__form">
              <div className="ageCheck__form--input">
                <Translate>
                  {({ translate }) => (
                    <InputMask
                      className={inputStyles}
                      name="birthdate"
                      value={this.state.birthdate}
                      onChange={this.onChange}
                      placeholder={translate('agecheck.placeholder')}
                      mask="99 | 99 | 9999"
                      maskChar="_"
                    />
                  )}
                </Translate>
              </div>
              <Button className="btn btn--primary btn--large btn--active form__button">
                <Translate id="agecheck.enter" />
              </Button>
              <Fade duration={500} when={this.state.isInvalid}>
                <div className="form__error">
                  <Translate id="agecheck.error" />
                </div>
              </Fade>
            </AgeCheckForm>
            <nav className="ageCheck__nav">
              <Link to="/legal">
                <Translate id="footer.links.legal" />
              </Link>
              <FlexibleLink
                to="privacyPolicy"
                containerPath="/legal"
                offset={-120}
              >
                <Translate id="footer.links.privacyPolicy" />
              </FlexibleLink>
            </nav>
          </div>
          <AgeCheckIcons className="ageCheck__icons">
            {ICONS.map((iconName) => (
              <li key={iconName}>
                <Icon name={`tequila-${iconName}`} />
              </li>
            ))}
          </AgeCheckIcons>
        </div>
      </AgeCheckContainer>
    );
  }
}

export default AgeCheck;
