import React, { Component } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Route, Switch } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import enTranslations from '../../locale/en/en.json';
import deTranslations from '../../locale/de/de.json';
import Symbols from '../../assets/icons/Symbols';
import RouterScrolling from '../RouterScrolling';
import verifyAge from '../../modules/verifyAge';
import { loadState } from '../../modules/localStorage';
import getBrowserLang from '../../utils/getBrowserLang';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

// Pages
import Home from '../../pages/Home';
import Legal from '../../pages/Legal';

const ProtectedRoute = verifyAge(Route);
const AVAILABLE_LANGS = ['en', 'de'];

class App extends Component {
  constructor(props) {
    super(props);

    const defaultLanguage =
      loadState('lang') || getBrowserLang(true, AVAILABLE_LANGS) || 'en';

    this.props.initialize({
      languages: [
        { name: 'English', code: 'en' },
        { name: 'Deutsch', code: 'de' }
      ],
      options: {
        renderToStaticMarkup,
        renderInnerHtml: true,
        defaultLanguage
      }
    });

    this.props.addTranslationForLanguage(enTranslations, 'en');
    this.props.addTranslationForLanguage(deTranslations, 'de');
  }

  render() {
    return (
      <div className="container">
        <RouterScrolling />
        <Header />
        <Switch>
          <Route path="/legal" component={Legal} />
          <ProtectedRoute component={Home} />
        </Switch>
        <Footer />
        <div style={{ display: 'none' }}>
          <Symbols />
        </div>
      </div>
    );
  }
}

export default withLocalize(App);
