import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { LocalizeProvider } from 'react-localize-redux';
import App from './components/App/App';

import '@fontsource/jost/latin-300.css';
import 'sanitize.css/sanitize.css';
import './globalStyles';

ReactDOM.render(
  <Router>
    <LocalizeProvider>
      <App />
    </LocalizeProvider>
  </Router>,
  document.getElementById('root')
);
