import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { Button } from './Styled';
import { saveState } from '../modules/localStorage';

const LanguageToggle = ({ className, languages, setActiveLanguage }) => {
  const inactiveLangs = languages.filter((l) => !l.active);
  if (inactiveLangs.length === 0) return null;

  return (
    <Button
      className={className}
      type="button"
      onClick={() => {
        setActiveLanguage(inactiveLangs[0].code);
        saveState(inactiveLangs[0].code, 'lang');
      }}
    >
      {inactiveLangs[0].name}
    </Button>
  );
};

export default withLocalize(LanguageToggle);
