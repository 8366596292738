import styled, { css } from 'react-emotion';
import AgeCheckBg from '../../assets/img/global/age-gate.jpg';
import { mq } from '../../config';

export const AgeCheckContainer = styled('div')`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: #000;
  background-image: url(${AgeCheckBg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .ageCheck__inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .ageCheck__content {
    text-align: center;
  }

  .ageCheck__logo {
    padding: 25px;
    margin: 0 auto;
    text-align: center;
    display: block;
  }

  .ageCheck__nav {
    margin-top: 3rem;
    font-size: 1.25em;
    color: #fff;
    a:first-child {
      margin-right: 1rem;
    }
  }

  .lang__toggle {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;

    @media (min-width: ${mq.md}) {
      top: 1rem;
      right: 1rem;
    }
  }
`;

export const AgeCheckIcons = styled('ul')`
  margin: 0;
  padding: 12.5px 0;
  width: 100%;
  text-align: center;
  list-style: none;
  position: absolute;
  bottom: 20px;
  color: rgba(255, 255, 255, 0.2);

  li {
    position: relative;
    display: inline-block;
    margin: 8px 8px 0;

    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }

    @media (min-width: ${mq.md}) {
      margin: 12.5px 12.5px 0;
    }
    @media (min-width: ${mq.lg}) {
      margin: 12.5px 37.5px 0 12.5px;
    }
    @media (min-width: ${mq.xl}) {
      margin: 25px 75px 0 75px;
    }
  }

  svg {
    width: 30px;
    height: 30px;

    @media (min-width: ${mq.sm}) {
      width: 40px;
      height: 40px;
    }
    @media (min-width: ${mq.md}) {
      width: 50px;
      height: 50px;
    }
    @media (min-width: ${mq.lg}) {
      width: 70px;
      height: 70px;
    }
    @media (min-width: ${mq.xl}) {
      width: 80px;
      height: 80px;
    }
  }
`;

export const AgeCheckForm = styled('form')`
  margin: 2rem auto 0;
  display: block;
  overflow: hidden;

  .ageCheck__form--input {
    width: 100%;
    margin: 0 auto 25px;
  }
  .form__error {
    padding-top: 25px;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.09375rem;
    color: red;
  }
`;

export const inputStyles = css`
  border: 0;
  outline: 0;
  background: 0;
  color: #fff;
  text-align: center;
  font-size: 24px;
  margin-bottom: 1rem;
  font-family: Courier, monospace;
  letter-spacing: 1px;

  @media (min-width: ${mq.md}) {
    font-size: 30px;
  }
`;
