import React from 'react';
import { Translate } from 'react-localize-redux';
import Modal from '../../components/Modal/Modal';
import { SectionScroll, Button } from '../../components/Styled';
import Slider from '../../components/Slider/Slider';
import {
  ProductInfo,
  ProductIcon,
  ProductImage,
  ProductImages,
  OverlaySlide,
  OverlayCloseTrigger
} from './productOverlayStyles';
import { sliderArrowsStyles } from './productsRangeStyles';

const renderSlide = ({ product }) => (
  <OverlaySlide className="slide" key={product.slug}>
    <ProductImages>
      <ProductImage>
        <img src={product.img} alt={product.imgAlt} />
      </ProductImage>
      <ProductImage>
        <img src={product.imgBack} alt={product.imgAlt} />
      </ProductImage>
    </ProductImages>
    <ProductInfo>
      <ProductIcon name={`tequila-${product.slug}-detailed`} />
      <h1>
        <Translate id={`product.${product.slug}.name`} />
      </h1>
      <h2>
        <Translate id={`product.${product.slug}.nickname`} />
        <br />
        <Translate id={`product.${product.slug}.details`} />
      </h2>
      <p>
        <Translate id={`product.${product.slug}.description`} />
      </p>
    </ProductInfo>
  </OverlaySlide>
);

const ProductOverlay = (props) => {
  if (!props.products) return null;

  return (
    <Modal
      overlayBgColor="#1b1b1b"
      overlayClassName="with-product-bg"
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      renderCloseButton={false}
    >
      <OverlayCloseTrigger onClick={props.onClose} />
      <div className="p-center">
        <Slider
          showArrows={true}
          showNav={false}
          arrowsClassName={sliderArrowsStyles}
          selectedIndex={props.activeIndex}
          onSlideIndexUpdate={props.onIndexUpdate}
        >
          {props.products.map((product) => renderSlide({ product }))}
        </Slider>
      </div>
      <SectionScroll>
        <Button
          onClick={props.onClose}
          className="btn btn--primary btn--active"
        >
          <Translate id="products.closeOverlay" />
        </Button>
      </SectionScroll>
    </Modal>
  );
};

export default ProductOverlay;
