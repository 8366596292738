/*
  List of native video events and properties:
  
  Source: https://github.com/mderrick/react-html5video/blob/master/src/video/constants.js
*/

export const VIDEO_EVENTS = [
  'abort',
  'canPlay',
  'canPlayThrough',
  'durationChange',
  'emptied',
  'encrypted',
  'ended',
  'error',
  'loadedData',
  'loadedMetadata',
  'loadStart',
  'pause',
  'play',
  'playing',
  'progress',
  'rateChange',
  'seeked',
  'seeking',
  'stalled',
  'suspend',
  'timeUpdate',
  'volumeChange',
  'waiting'
];

export const VIDEO_PROPERTIES = [
  'audioTracks',
  'autoPlay',
  'buffered',
  'controller',
  'controls',
  'currentSrc',
  'currentTime',
  'defaultMuted',
  'defaultPlaybackRate',
  'duration',
  'ended',
  'error',
  'loop',
  'mediaGroup',
  'muted',
  'networkState',
  'paused',
  'playbackRate',
  'played',
  'preload',
  'readyState',
  'seekable',
  'seeking',
  'src',
  'startDate',
  'textTracks',
  'videoTracks',
  'volume'
];
