import React, { Component } from 'react';
import AgeCheck from '../components/AgeCheck/AgeCheck';
import { loadState, saveState } from "../modules/localStorage";

const getDisplayName = c => c.displayName || c.name || 'Component';

const AGECHECK_KEY = 'ageCheck';

export default ComposedComponent => {
  class HOC extends Component {
    constructor() {
      super();

      this.state = {
        isVerified: false
      };
    }

    componentWillMount() {
      const { isVerified } = loadState(AGECHECK_KEY) || {};
      this.setState({ isVerified });
    }

    verify = () => {
      this.setState({ isVerified: true }, () => {
        saveState(this.state, AGECHECK_KEY);
      });
    };

    render() {
      const { isVerified } = this.state;
      if (!isVerified) return <AgeCheck onSuccess={this.verify} />;

      return <ComposedComponent {...this.props} />;
    }
  }

  HOC.displayName = `verifyAge(${getDisplayName(ComposedComponent)}`;  

  return HOC;
};
