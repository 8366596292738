import styled from 'react-emotion';
import { mq } from '../../config';
import { GradientBar } from '../../components/Styled';

export const TimelineSection = styled('section')`
  width: 100%;
  min-height: 100vh;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;

  .timeline__content {
    position: relative;
    flex-grow: 1;
    margin: 2rem 0 4rem;

    @media (min-width: ${mq.sm}) {
      margin-bottom: 7rem;
    }
  }
`;

export const TimelineHeader = styled('header')`
  font-size: 1.125rem;
  line-height: 1.5625rem;
  margin-top: 3rem;

  &::after {
    background-image: linear-gradient(to right, #d7b422 50%, rgba(255, 255, 255, 0) 100%);
  }

  @media (min-width: ${mq.sm}) {
    font-size: 1.875rem;
    line-height: 2.34375rem;

    &::after {
      background-image: linear-gradient(
        to right,
        rgba(255, 0, 0, 0) 0%,
        #d7b422 50%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
`;

export const TimelineBg = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;

  &.faded {
    opacity: 0.3;

    @media (min-width: ${mq.sm}) {
      opacity: 0.6;
    }
  }
`;

export const TimelineBar = styled(GradientBar)`
  display: none;

  @media (min-width: ${mq.sm}) {
    display: block;
    left: 50px;
  }

  @media (min-width: ${mq.lg}) {
    left: 50%;
  }
`;

export const TimelineFact = styled('div')`
  padding: 25px;
  text-align: left;

  @media (min-width: ${mq.sm}) {
    padding: 25px 50px;
    max-width: 75%;
    margin-top: ${(props) => props.marginTop || 0}rem;

    &.left,
    &.right {
      margin-left: 50px;
    }
  }

  @media (min-width: ${mq.lg}) {
    width: 50%;
    max-width: 550px;

    &.left {
      text-align: right;
      margin-right: 50%;
      margin-left: auto;
    }

    &.right {
      margin-left: 50%;
    }
  }

  .timeline__fact--title {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .timeline__fact--text {
    font-size: 0.875rem;
    line-height: 1.1375rem;
  }

  @media (min-width: ${mq.xs}) {
    .timeline__fact--text {
      font-size: 1.05rem;
      line-height: 1.3125rem;
    }
  }

  @media (min-width: ${mq.sm}) {
    .timeline__fact--title {
      font-size: 1.75rem;
      line-height: 2.34375rem;
    }
  }

  @media (min-width: ${mq.lg}) {
    .timeline__fact--text {
      font-size: 1.025rem;
      line-height: 1.875rem;
    }
  }
`;

export const TimelineFactTitle = styled('h2')`
  text-transform: uppercase;
  position: relative;

  &::before {
    position: absolute;
    top: 50%;
    left: -55px;
    margin-top: -5px;
    content: ' ';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #fff;
    display: none;

    @media (min-width: ${mq.sm}) {
      display: block;
    }
  }

  @media (min-width: ${mq.lg}) {
    .timeline__fact.left &::before {
      left: auto;
      right: -55px;
    }
  }
`;
