import styled from 'react-emotion';
import { Section } from '../../components/Styled';
import StoryBackgroundBlurred from '../../assets/img/story/background-two.jpg';
import { mq } from '../../config';

export const StorySection = styled(Section)`
  background-image: url(${StoryBackgroundBlurred});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: 5rem 0;

  @media (min-width: ${mq.lg}) {
    padding: 7rem 0;
  }

  .story__steps {
    overflow: hidden;
  }

  .story__btn {
    margin-top: 25px;
  }
`;

export const StoryPart = styled('div')`
  overflow: hidden;

  h4 {
    margin-top: 1.5rem;
    opacity: 0.5;
    font-size: 0.875rem;
    line-height: 1.09375rem;
    text-transform: uppercase;
    font-weight: lighter;
    display: none;

    @media (min-width: ${mq.sm}) {
      display: block;
    }
  }
  p {
    padding: 0 10px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: 25px 0 0;

    @media (min-width: ${mq.sm}) {
      font-size: 1.125rem;
      line-height: 1.5625rem;
      padding: 0;
      margin: 0;
    }
  }
`;
