import SnakeMargaritaImg from '../../assets/img/drinks/sierramilenario-drinks-01-snake-margarita@2x.png';
import JaguarImg from '../../assets/img/drinks/sierramilenario-drinks-02-jaguar@2x.png';
import MonkeyRacImg from '../../assets/img/drinks/sierramilenario-drinks-03-monkey-rac@2x.png';
import OldEagleImg from '../../assets/img/drinks/sierramilenario-drinks-04-old-eagle@2x.png';
import SmokedBandersImg from '../../assets/img/drinks/sierramilenario-drinks-05-smoked-bandera@2x.png';
import MexicanRabbitImg from '../../assets/img/drinks/sierramilenario-drinks-06-mexican-rabbit@2x.png';

export const drinksImages = [
  {
    name: 'THE SNAKE MARGARITA',
    img: SnakeMargaritaImg
  },
  {
    name: 'THE JAGUAR',
    img: JaguarImg
  },
  {
    name: 'THE MONKEY RAC',
    img: MonkeyRacImg
  },
  {
    name: 'THE OLD EAGLE',
    img: OldEagleImg
  },
  {
    name: 'SMOKED BANDERA',
    img: SmokedBandersImg
  },
  {
    name: 'THE MEXICAN RABBIT',
    img: MexicanRabbitImg
  }
];
