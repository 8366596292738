/*
  Inspiration taken from:
  https://github.com/mderrick/react-html5video/blob/master/src/video/video.js
*/

import React, { Component } from 'react';
import { VIDEO_EVENTS, VIDEO_PROPERTIES } from './constants';

class VideoPlayer extends Component {
  constructor() {
    super();

    this.videoEl = null;

    this.setVideoContainerRef = (el) => {
      this.videoContainer = el;
    };

    this.state = {};
  }

  componentDidMount() {
    this.videoEl = this.videoContainer.getElementsByTagName('video')[0];

    this.bindEvents();
  }

  componentWillUnmount() {
    this.unbindEvents();
  }

  updateState = () => {
    this.setState(
      VIDEO_PROPERTIES.reduce((p, c) => {
        p[c] = this.videoEl && this.videoEl[c];
        return p;
      }, {})
    );
  };

  bindEvents() {
    VIDEO_EVENTS.forEach((event) => {
      this.videoEl.addEventListener(event.toLowerCase(), this.updateState);
    });
  }

  unbindEvents() {
    VIDEO_EVENTS.forEach((event) => {
      this.videoEl.removeEventListener(event.toLowerCase(), this.updateState);
    });
  }

  toggleAudio = () => {
    if (this.videoEl.volume === 1) {
      this.videoEl.volume = 0;
    } else {
      this.videoEl.volume = 1;
    }
  };

  togglePlayback = () => {
    if (!this.videoEl.paused) {
      this.videoEl.pause();
    } else {
      this.videoEl.play();
    }
  }

  render() {
    return (
      <div ref={this.setVideoContainerRef} className="video__player">
        {this.props.render && this.props.render({
            videoState: this.state,
            videoEl: this.videoEl,
            toggleAudio: this.toggleAudio,
            togglePlayback: this.togglePlayback
          })}
      </div>
    );
  }
}

export default VideoPlayer;
