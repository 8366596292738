import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import styled from 'react-emotion';
import cc from 'classcat';

const ModalOverlay = styled('div')`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${(props) => props.zIndex || 999};
  ${(props) =>
    props.overlayBgColor && `background-color: ${props.overlayBgColor}`};
`;

const ModalContent = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

class Modal extends Component {
  static defaultProps = {
    isOpen: true,
    closeOnEsc: true,
    closeOnOutsideClick: true,
    renderCloseButton: true,
    onRequestClose: () => {}
  };

  onOutsideClick = (event) => {
    if (!this.props.closeOnOutsideClick) return;
    if (event.target.dataset.modal) {
      this.props.onRequestClose(event);
    }
  };

  handleKeyDown = (event) => {
    // Close on Esc click
    if (event.keyCode === 27) {
      event.preventDefault();
      this.props.onRequestClose(event);
    }
  };

  componentDidMount() {
    if (this.props.closeOnEsc) {
      document.addEventListener('keydown', this.handleKeyDown);
    }
  }

  componentWillUnmount() {
    if (this.props.closeOnEsc) {
      document.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  render() {
    if (!this.props.isOpen) return null;
    return (
      <Portal>
        <ModalOverlay
          className={cc([this.props.overlayClassName, 'modal__overlay'])}
          data-modal="true"
          onClick={this.onOutsideClick}
          overlayBgColor={this.props.overlayBgColor}
          zIndex={this.props.zIndex}
        >
          <ModalContent
            className={cc([this.props.className, 'modal__content'])}
          >
            {this.props.renderCloseButton && (
              <div
                className="modal__btn--close"
                onClick={this.props.onRequestClose}
              />
            )}
            {this.props.children}
          </ModalContent>
        </ModalOverlay>
      </Portal>
    );
  }
}

export const modalPropTypes = {
  isOpen: PropTypes.bool,
  closeOnEsc: PropTypes.bool,
  closeOnOutsideClick: PropTypes.bool,
  onRequestClose: PropTypes.func,
  overlayBgColor: PropTypes.string,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  zIndex: PropTypes.number,
  renderCloseButton: PropTypes.bool
};

Modal.propTypes = modalPropTypes;

export default Modal;
