export const getDurationPercentage = (video) => {
  let durationPercentage = 0;

  if (video.currentTime > 0) {
    durationPercentage = Math.floor(
      (100 / video.duration) * video.currentTime
    );
  }
  
  return durationPercentage;
};