import { Component } from 'react';
import { scroller } from 'react-scroll';
import { withRouter } from 'react-router-dom';

class RouterScrolling extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const { scrollEl } = this.props.location.state || {};
      const { scrollEl: scrollElPrev } = prevProps.location.state || {};
      if (scrollEl != null && scrollEl !== scrollElPrev) {
        scroller.scrollTo(scrollEl, {
  smooth: true,
        });
      } else {
        window.scrollTo(0, 0);
      }
    }
  }

  render() {
    return this.props.children || null;
  }
}

export default withRouter(RouterScrolling);
