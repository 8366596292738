import React from 'react';
import styled, { css } from 'react-emotion';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import FlexibleLink from '../FlexibleLink';
import Icon from '../Icon';
import { buttonStyles } from '../Styled';
import './footer.css';
import { mq } from '../../config';
import InstagramIcon from '../../assets/img/sharing/instagram.png';

const instagramIconStyle = css`
  display: inline-block;
  height: 24px;
  width: 24px;
  background-image: url(${InstagramIcon});
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: center;
`;

const FooterInner = styled('div')`
  width: 100%;
  padding: 0 12.5px 12.5px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .footer__nav {
    flex: 1;
  }

  .footer__nav a {
    padding: 8.5px;
  }

  .footer__scroll {
    display: none;
  }

  @media (min-width: ${mq.md}) {
    padding: 0 25px 12.5px;

    .footer__social {
      flex: 1;
    }
    .footer__scroll {
      display: block;
    }
    .footer__nav a {
      padding: 8.5px 12.5px;
    }
  }
`;

const Footer = () => {
  return (
    <footer className="footer">
      <FooterInner className="footer__inner">
        <div className="footer__nav">
          <FlexibleLink
            to="about"
            containerPath="/"
            className={`${buttonStyles} btn btn--primary`}
          >
            <Translate id="footer.links.contact" />
          </FlexibleLink>
          <Link to="/legal" className={`${buttonStyles} btn btn--primary`}>
            <Translate id="footer.links.legal" />
          </Link>
          <FlexibleLink
            to="privacyPolicy"
            containerPath="/legal"
            className={`${buttonStyles} btn btn--primary`}
            offset={-120}
          >
            <Translate id="footer.links.privacyPolicy" />
          </FlexibleLink>
        </div>
        <div className="footer__scroll">
          <Icon name="scroll-icon" />
        </div>
        <div className="footer__social">
          <a
            href="https://www.instagram.com/sierramilenario"
            className={instagramIconStyle}
          >
            <span className="sr-only">Instagram</span>
          </a>
        </div>
      </FooterInner>
    </footer>
  );
};

export default Footer;
