import styled from 'react-emotion';

export const HeaderContainer = styled('header')`
  margin: 0 auto;
  padding: 25px 25px 35px;
  width: 100%;
  text-align: center;
  position: fixed;
  z-index: 10;
  transition: all 300ms ease;
  background-image: linear-gradient(
    to bottom,
    hsl(0, 0%, 0%) 0%,
    hsla(0, 0%, 0%, 0.987) 10.4%,
    hsla(0, 0%, 0%, 0.951) 19.5%,
    hsla(0, 0%, 0%, 0.896) 27.7%,
    hsla(0, 0%, 0%, 0.825) 34.9%,
    hsla(0, 0%, 0%, 0.741) 41.5%,
    hsla(0, 0%, 0%, 0.648) 47.4%,
    hsla(0, 0%, 0%, 0.55) 53%,
    hsla(0, 0%, 0%, 0.45) 58.2%,
    hsla(0, 0%, 0%, 0.352) 63.4%,
    hsla(0, 0%, 0%, 0.259) 68.5%,
    hsla(0, 0%, 0%, 0.175) 73.9%,
    hsla(0, 0%, 0%, 0.104) 79.5%,
    hsla(0, 0%, 0%, 0.049) 85.7%,
    hsla(0, 0%, 0%, 0.013) 92.4%,
    hsla(0, 0%, 0%, 0) 100%
  );
  background-repeat: no-repeat;
`;
