import styled, { css } from 'react-emotion';
import { Section } from '../../components/Styled';
import Icon from '../../components/Icon';
import { mq } from '../../config';
import SliderButtons from '../../assets/img/global/slider-buttons.png';

export const ProductsSection = styled(Section)`
  overflow: hidden;
`;

export const ProductsContent = styled('div')`
  width: 100%;
  max-width: 960px;
  z-index: 2;
  text-align: center;

  @media (min-width: ${mq.xl}) {
    max-width: 1280px;
  }
`;

export const ProductsGrid = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
`;

export const ProductCol = styled('div')`
  flex: 1 auto;
  position: relative;
  transition: opacity 300ms ease;
  color: ${(props) => (props.color ? props.color : '#fff')};

  .slide--prev,
  .slide--next {
    position: absolute;
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    top: 0;
    display: none;

    .product__detail {
      display: none;
    }

    @media (min-width: ${mq.xs}) {
      display: block;
    }
  }

  .slide--prev {
    left: 0;
    transform: translateX(-35%);
  }
  .slide--next {
    right: 0;
    transform: translateX(35%);
  }

  @media (min-width: ${mq.mm}) {
    .slide--prev {
      left: 0;
      transform: translateX(-50%);
    }
    .slide--next {
      right: 0;
      transform: translateX(50%);
    }
  }

  &.active {
    .slide--prev,
    .slide--next {
      opacity: 0.2;
    }
  }

  @media (min-width: ${mq.lg}) {
    top: -25px;
    color: #fff;
  }

  @media (min-width: ${mq.xl}) {
    top: 0;
  }

  .products__grid:hover & {
    @media (min-width: ${mq.lg}) {
      opacity: 0.3;
      &:hover {
        opacity: 1;
        color: ${(props) => (props.color ? props.color : '#fff')};
      }
      &:hover .product__image {
        transform: scale(1.2) translateZ(0);
        transform-origin: center;
      }
      &:hover .product__info {
        opacity: 1;
      }
    }
  }
`;

export const ProductImage = styled('img')`
  width: 60%;
  height: auto;
  vertical-align: bottom;
  padding: 0 25px;
  transition: transform 200ms ease;
  backface-visibility: hidden;
  transform: translateZ(0);
  cursor: pointer;

  @media (min-width: ${mq.xs}) {
    width: auto;
    max-height: 35vh;
  }

  @media (min-width: ${mq.lg}) {
    width: 100%;
    max-height: none;
  }
`;

export const ProductDetails = styled('div')`
  padding-top: 20px;

  @media (min-width: ${mq.lg}) {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -175px;
    padding-top: 50px;
  }

  @media (min-width: ${mq.xl}) {
    bottom: -200px;
  }
`;

export const ProductInfo = styled('div')`
  opacity: 1;
  transition: opacity 500ms ease;
  text-transform: uppercase;
  font-size: 0.875rem;
  line-height: 1.09375rem;

  h1,
  h2,
  .btn {
    font-size: inherit;
    line-height: inherit;
  }

  h2 {
    color: #fff;
  }

  @media (min-width: ${mq.mm}) {
    font-size: 1.125rem;
    line-height: 1.5625rem;
  }

  @media (min-width: ${mq.lg}) {
    opacity: 0;
    font-size: 0.7rem;
    line-height: 0.875rem;
  }

  @media (min-width: ${mq.xl}) {
    font-size: 0.875rem;
    line-height: 1.09375rem;
  }
`;

export const ProductIcon = styled(Icon)`
  display: block;
  margin: 0 auto 15px;
  height: 28px;
  width: 28px;
  fill: currentColor;

  @media (min-width: ${mq.xs}) {
    height: 40px;
    width: 40px;
  }

  @media (min-width: ${mq.xl}) {
    height: 50px;
    width: 50px;
  }
`;

export const ProductControls = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 1280px;
  width: 90%;
  z-index: 10;

  > div {
    cursor: pointer;
    position: absolute;
    background-image: url(${SliderButtons});
    transform: scale(0.7);
    transform-origin: center;
    height: 63px;
  }

  .close {
    width: 63px;
    top: 50px;
    right: 0;
    background-repeat: no-repeat;
    background-position: -67px 0px;
  }

  .prev,
  .next {
    width: 30px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }

  .prev {
    background-position: 0px 0px;
    left: 0;
  }

  .next {
    background-position: -31px 0px;
    right: 0;
  }
`;

export const sliderArrowsStyles = css`
  max-width: 1230px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  margin: 0 auto;

  > .slider__arrow {
    cursor: pointer;
    position: absolute;
    background-image: url(${SliderButtons});
    background-repeat: no-repeat;
    transform: scale(0.7);
    transform-origin: center;
    height: 63px;
    width: 30px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    padding: 0;

    &::after,
    &::before {
      display: none;
    }
  }

  .slider__arrow--left {
    background-position: 0px 0px;
    left: 0;
  }

  .slider__arrow--right {
    background-position: -31px 0px;
    right: 0;
  }

  &.slider__arrows--upper > .slider__arrow {
    top: 40%;
  }
`;
