import React from 'react';
import { Translate } from 'react-localize-redux';
import Fade from 'react-reveal/Fade';
import {
  responsiveHeadline,
  headlineGradient,
  Button,
  NarrowContent
} from '../../components/Styled';
import { StoryPart, StorySection } from './storyStyles';

const STORY_STEPS = ['one', 'two', 'three', 'four', 'five'];

const renderStep = (step) => (
  <StoryPart key={step} className="story__part">
    <h4>
      <Translate id={`story.${step}.chapter`} />
    </h4>
    <p>
      <Translate id={`story.${step}.body`} />
    </p>
  </StoryPart>
);

class Story extends React.Component {
  state = {
    showAllChapters: false
  };

  toggleChapters = () => {
    this.setState({ showAllChapters: !this.state.showAllChapters });
  };

  render() {
    return (
      <StorySection className="section story flex-center top-gradient bottom-gradient">
        <NarrowContent className="story__content">
          <div>
            <header
              className={`story__header ${headlineGradient} ${responsiveHeadline}`}
            >
              <Translate id="story.header" />
            </header>
          </div>
          <div className="story__steps">
            {renderStep(STORY_STEPS[0])}
            <Fade
              top
              duration={1500}
              collapse
              when={this.state.showAllChapters}
            >
              {STORY_STEPS.slice(1, STORY_STEPS.length).map(renderStep)}
            </Fade>
          </div>
          <Button
            className="btn btn--primary btn--active story__btn"
            onClick={this.toggleChapters}
          >
            <Translate
              id={
                this.state.showAllChapters ? 'story.collapse' : 'story.expand'
              }
            />
          </Button>
        </NarrowContent>
      </StorySection>
    );
  }
}

export default Story;
