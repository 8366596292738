import React, { Fragment } from 'react';

export default {
  en: {
    title: 'Legal Information',
    legalNotice: (
      <Fragment>
        <h4>Legal Notice</h4>
        <p>
          The information on this Web site is regularly checked and updated by
          BORCO-MARKEN-IMPORT. However, some of the information may have changed
          since it was last checked. For this reason, we cannot guarantee that
          the information provided is up to date, correct, or complete. The same
          applies to any Web site linked to on this site. BORCO-MARKEN-IMPORT is
          not responsible for the content of Web sites reached via such links.
          <br /> BORCO-MARKEN-IMPORT also reserves the right to change and add
          to the information provided. The content and structure of
          BORCO-MARKEN-IMPORT Web sites are protected by copyright. Permission
          must first be obtained from BORCO-MARKEN-IMPORT before reproducing any
          of the information or data and, in particular, before utilizing text,
          in whole or part, or images for private purposes.
          <br /> The comments on our blog reflect only the opinions of
          individual contributors. BORCO cannot undertake any contractual
          responsibility whatsoever for the completeness or correctness of the
          content.
        </p>
        <h4>Design</h4>
        <p>Exposure London</p>
        <h4>Technical Realisation</h4>
        <p>
          <a
            href="https://www.teege.me"
            title="teege.me GmbH Design & Programmierung"
            target="_blank"
            rel="noopener noreferrer"
          >
            teege.me GmbH Hamburg
          </a>
        </p>
      </Fragment>
    ),
    privacyPolicy: (
      <Fragment>
        <h4>Privacy Policy</h4>
        <p>
          BORCO-Marken-Import Matthiesen GmbH &amp; CO KG will handle your data
          responsibly. We will of course adhere to legal regulations, especially
          the German Federal Data Protection Act and the German broadcast media
          act (Telemediengesetz), when collecting, processing, and utilizing
          your data.
        </p>
        <p>
          Our Web site may contain links to other Web sites belonging to other
          providers to which this data privacy declaration does not extend.
        </p>
        <p>
          Some of the features of our Web site use “cookies.” Cookies are small
          text files that are stored in the cache of the Internet browser used
          by the person visiting the site. These cookies allow the browser to be
          recognized when the site is visited again. The purpose of this is to
          make it easier for you to use the Web site. For example, once you have
          logged in as a blog user, you do not have to keep logging in again
          whenever you switch back and forth between different subpages,
          provided you are recognized by means of a cookie. Settings you have
          chosen can also be stored using cookies. You can prevent the use of
          cookies at any time by changing your browser settings, and you can
          delete cookies that have already been stored. However, doing this may
          restrict some of the functionality of the Web site.
        </p>
        <p>
          As with any Web site, when you visit the Sierra Tequila site, your
          computer will send data to our Web server that it requires in order to
          make the Web site available (such as the date and time of request,
          requested page, browser used, available plug-ins, and so on). This
          data is only used for the purposes of presenting our Web site and in
          the ways described above and will not be sent to third parties. Your
          IP address will be made anonymous by truncating it before it is stored
          with any of the data.
        </p>
        <p>
          Upon request, we can inform you as two which data are currently being
          stored, either in writing or electronically. Please send your request
          to:
        </p>
        <p>
          BORCO-MARKEN-IMPORT <br /> MATTHIESEN GMBH &amp; CO. KG <br />
          Datenschutzbeauftragter <br /> Winsbergring 12-22 <br /> 22525 Hamburg
          <br /> Deutschland <br />
          Geschäftsführer: Markus Kohrs-Lichte, Bodo-Joachim Wendenburg <br/>
          E-Mail: datenschutzbeauftragter(at)borco.com
        </p>
        <p>
          You can use the same address to tell us you no longer want us to store
          your data and to exercise your right to have data corrected or
          blocked. We would like to remind you that you may revoke your consent
          at any time by e-mail or letter, with effect from that point onward.
        </p>
      </Fragment>
    )
  },
  de: {
    title: 'Rechtliche Informationen / Impressum',
    legalNotice: (
      <Fragment>
        <h4>Hinweise</h4>
        <p>
          Die Informationen auf dieser Website wird regelmäßig von
          BORCO-MARKEN-Import geprüft und erneuert. Dennoch könnten sich gewisse
          Informationen geändert haben, seit das letzte mal geprüft wurde. Aus
          diesem Grund können wir nicht garantieren, dass die Informationen
          aktuell, richtig, oder vollständig sind. Das selbe gilt für alle
          Websites, auf die diese Website verlinkt.
          <br /> BORCO-MARKEN-IMPORT ist nicht verantwortlich für den Inhalt von
          verlinkten Websites und behält sich auch das Recht vor, die
          Informationen auf dieser Website zu ändern oder zu ergänzen. Die
          Inhalte von BORCO-MARKEN-IMPORT-Websites sind urheberrechtlich
          geschützt. Zunächst muss eine Erlaubnis bei BORCO-MARKEN-IMPORT
          eingeholt werden, bevor Inhalte dieser Website, im speziellen Text und
          Bilder, für private oder kommerzielle Zwecke eingesetzt werden.
          <br /> Die Kommentare auf unserem Blog stellen nur die Meinung von
          deren individuellen Autoren dar. BORCO kann keine vertragliche
          Verantwortung jeglicher Art für die Vollständigkeit oder Richtigkeit
          des Inhalts eingehen.
        </p>
        <h4>Design</h4>
        <p>Exposure London</p>
        <h4>Umsetzung / Programmierung</h4>
        <p>
          <a
            href="https://www.teege.me"
            title="teege.me GmbH Design & Programmierung"
            target="_blank"
            rel="noopener noreferrer"
          >
            teege.me GmbH Hamburg
          </a>
        </p>
      </Fragment>
    ),
    privacyPolicy: (
      <Fragment>
        <h4>Datenschutzerklärung</h4>
        <p>
          Die BORCO-Marken-Import Matthiesen GmbH &amp; Co. KG behandelt deine
          Daten verantwortungsvoll. Selbstverständlich halten wir uns an
          geltendes Recht, vor allem an die Deutschen Datenschutzgesetze (DSGVO)
          und das Telemediengesetz, wenn wir deine Daten speichern, verarbeiten,
          oder nutzen.
        </p>
        <p>
          Unsere Website kann Links zu externen Websites enthalten, für die
          unsere Datenschutzerklärung nicht gilt.
        </p>
        <p>
          Einige Funktionen unserer Websites nutzen Cookies. Cookies sind kleine
          Textdateien, die im Internetbrowser des Besuchers, also dir,
          gespeichert werden. Diese Cookies erlauben es, zum Beispiel die
          erfolgreiche Mindest-Alter-Bestätigung zu speichern, damit diese nicht
          bei jedem Besuch der Website erneut eingegeben werden muss. Du kannst
          jederzeit der Nutzung von Cookies widersprechen, indem du die Cookies
          in deinem Browser löschst, oder das Akzeptieren von neuen Cookies in
          deinem Browser deaktivierst. Dadurch werden einige Funktionen dieser
          Website aber nicht mehr für dich nutzbar sein.
        </p>
        <p>
          Wie bei jeder Website, ist es auch bei der Sierra Milenario Website
          so, dass dein Computer gewisse Daten an unsere Server senden muss,
          damit die Website für dich erreichbar wird. Dazu gehört das Datum und
          die Uhrzeit deiner Anfrage, die URL der angeforderten Website, der
          Name deines Browsers, und so weiter. Diese Daten werden nur dafür
          genutzt, dass die Website dir in der beschriebenen Art angezeigt
          werden kann, und werden niemals an Dritte weitergegeben. Deine
          IP-Adresse wird anonym gemacht, indem sie verkürzt gespeichert wird.
        </p>
        <p>
          Auf Nachfrage können wir dich informieren, welche Daten wir aktuell zu
          deinem Gerät gespeichert haben, entweder schriftlich oder digital.
          Bitte sende deine Anfrage an:
        </p>
        <p>
          BORCO-MARKEN-IMPORT <br /> MATTHIESEN GMBH &amp; CO. KG <br />
          Datenschutzbeauftragter <br /> Winsbergring 12-22 <br /> 22525 Hamburg
          <br /> Deutschland <br />
          Geschäftsführer: Markus Kohrs-Lichte, Bodo-Joachim Wendenburg <br/>
          E-Mail: datenschutzbeauftragter(at)borco.com
        </p>
        <p>
          Du kannst dieselbe Adresse nutzen wenn du uns mitteilen möchtest, dass
          du nicht mehr möchtest, dass deine Daten bei uns gespeichert werden,
          oder sie korrigiert werden sollen. Wir erinnern dich hiermit daran,
          dass du deine Zustimmung zur Speicherung jederzeit widerrufen kannst,
          per E-Mail oder Post, ab dem Zeitpunkt, an dem dein Widerruf bei uns
          eingeht.
        </p>
      </Fragment>
    )
  }
};
