import React from 'react';
import cc from 'classcat';

const Icon = (props) => (
  <svg className={cc([props.className, 'icon', `icon-${props.name}`])}>
    <use xlinkHref={`#${props.name}`} />
  </svg>
);

export default Icon;
