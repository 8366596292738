import React from 'react';
import styled from 'react-emotion';
import { Translate } from 'react-localize-redux';
import Icon from '../../components/Icon';
import { Section, VideoBgWrapper } from '../../components/Styled';
import { mq, VIDEOS_PATH } from '../../config';
import IntroMessage from '../../assets/img/intro/message.png';

const IntroSection = styled(Section)`
  text-align: center;
  background-color: #111;

  .intro__image {
    width: 100%;
    height: auto;
  }
`;

const IntroContent = styled('div')`
  z-index: 1;
  width: 100%;
  padding: 12.5px;
  max-width: 620px;

  @media (min-width: ${mq.mm}) {
    padding: 50px;
    margin-top: 50px;
  }

  @media (min-width: ${mq.md}) {
    max-width: 960px;
  }

  @media (min-width: ${mq.lg}) {
    padding: 0;
  }
`;

const IntroIconsList = styled('ul')`
  margin: 0;
  padding: 12.5px 0;
  width: 100%;
  text-align: center;
  list-style: none;

  li {
    position: relative;
    display: inline-block;
    width: 75px;
    margin: 25px 37.5px 0 37.5px;
    &:first-of-type {
      margin-left: 0px;
    }

    &:last-of-type {
      margin-right: 0px;
    }
    svg {
      width: 100%;
      height: auto;
    }
  }

  @media screen and (max-width: 1280px) {
    li {
      width: 50px;
      margin: 12.5px 37.5px 0 12.5px;
      svg {
        height: 60px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    li {
      width: 50px;
    }
  }

  @media screen and (max-width: 768px) {
    li {
      margin: 12.5px 12.5px 0 12.5px;
    }
  }

  @media screen and (max-width: 670px) {
    li {
      width: 35px;
      margin: 0 8.33333px;
    }
  }

  @media screen and (max-width: 320px) {
    li {
      margin: 10px 5px 0 5px;
      width: 20px;
      svg {
        height: 20px;
      }
    }
  }

  @media screen and (max-width: 320px) {
    padding: 6.25px 0;
  }
`;

const IntroCopy = styled('p')`
  margin: 1.5rem 0 0;
  font-size: 0.875rem;
  line-height: 1.09375rem;

  @media (min-width: ${mq.mm}) {
    line-height: 1.3125rem;
  }

  @media (min-width: ${mq.xl}) {
    font-size: 1.125rem;
    line-height: 1.875rem;
  }
`;

const icons = [
  'tequila-blanco-detailed',
  'tequila-reposado-detailed',
  'tequila-anejo-detailed',
  'tequila-extra-anejo-detailed',
  'tequila-cafe-detailed',
  'tequila-fumado-detailed',
];

const Intro = () => {
  return (
    <IntroSection className="section intro">
      <IntroContent className="intro__content p-center">
        <Translate>
          {({ translate }) => (
            <img className="intro__image" src={IntroMessage} alt={translate('intro.image')} />
          )}
        </Translate>

        <IntroIconsList className="intro__icons">
          {icons.map((icon) => (
            <li key={icon}>
              <Icon name={icon} />
            </li>
          ))}
        </IntroIconsList>
        <IntroCopy>
          <Translate id="intro.paragraph" />
        </IntroCopy>
      </IntroContent>
      <VideoBgWrapper className="intro__video">
        <video preload="auto" playsInline autoPlay muted loop>
          <source src={VIDEOS_PATH + 'loop.mp4'} type="video/mp4" />
        </video>
      </VideoBgWrapper>
    </IntroSection>
  );
};

export default Intro;
