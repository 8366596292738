import React, { Fragment } from 'react';
import { Element } from 'react-scroll';

// Sections
import Intro from '../sections/Intro/Intro';
import Trailer from '../sections/Trailer/Trailer';
import ProductsIntro from '../sections/ProductsIntro/ProductsIntro';
import ProductsRange from '../sections/ProductsRange/ProductsRange';
import Drinks from '../sections/Drinks/Drinks';
import Process from '../sections/Process/Process';
import Story from '../sections/Story/Story';
import About from '../sections/About/About';

const Home = () => {
  return (
    <Fragment>
      <Element name="intro">
        <Intro />
      </Element>
      <Element name="trailer">
        <Trailer />
      </Element>
      <ProductsIntro />
      <Element name="products">
        <ProductsRange />
      </Element>
      <Element name="drinks">
        <Drinks />
      </Element>
      <Element name="process">
        <Process />
      </Element>
      <Element name="story">
        <Story />
      </Element>
      <Element name="about">
        <About />
      </Element>
    </Fragment>
  );
};

export default Home;
