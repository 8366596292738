import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { drinksImages } from './drinksData';
import drinksTranslations from '../../locale/drinks';
import { headlineGradient, FixedWrapper } from '../../components/Styled';
import {
  DrinkImage,
  DrinkBgImage,
  DrinksSection,
  DrinkName,
  DrinkDetails,
  DrinkInfo
} from './drinksStyles';

class Drinks extends React.Component {
  render() {
    if (!this.props.activeLanguage) return null;

    const drinksData = drinksTranslations[this.props.activeLanguage.code];

    return (
      <div className="drinks__sections">
        {drinksData.map((drink, key) => {
          const { img: drinkImg } = drinksImages[key] || {};

          return (
            <DrinksSection
              key={key}
              minHeight="800px"
              className="section with-product-bg bottom-gradient"
            >
              <FixedWrapper>
                <DrinkBgImage
                  style={{ backgroundImage: `url(${drinkImg})` }}
                  className="drink__image"
                />
              </FixedWrapper>
              <div className="drink__container header-offset">
                <div className="drink__content">
                  <div className="block-center">
                    <DrinkName className={`${headlineGradient} drink__header`}>
                      {drink.name}
                    </DrinkName>
                  </div>
                  <DrinkInfo className="drink__info">
                    <p className="drink__info--description text-center">
                      {drink.description}
                    </p>
                  </DrinkInfo>
                  <DrinkDetails className="drink__details">
                    {drink.recipe}
                  </DrinkDetails>
                  <DrinkImage>
                    <img alt={drink.name} src={drinkImg} />
                  </DrinkImage>
                </div>
              </div>
            </DrinksSection>
          );
        })}
      </div>
    );
  }
}

export default withLocalize(Drinks);
