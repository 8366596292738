import styled from 'react-emotion';
import Icon from '../../components/Icon';
import { mq } from '../../config';

export const ProductImages = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;

  @media (min-width: ${mq.sm}) {
    height: auto;
    padding: 25px;
    position: relative;
    opacity: 1;
  }

  @media (min-width: ${mq.md}) {
    height: 100%;
    padding: 0;
    position: absolute;
  }
`;

export const ProductImage = styled('div')`
  display: inline-block;
  position: absolute;
  width: 50%;
  height: 100%;

  &:first-of-type {
    left: 25px;
  }
  &:last-of-type {
    right: 25px;
  }

  @media (min-width: 375px) {
    width: 25%;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    margin: -20px auto 0;
    height: auto;

    &:first-of-type {
      left: 20px;
      z-index: 1;
    }
    &:last-of-type {
      left: -20px;
      right: 0;
    }
  }

  @media (min-width: 768px) {
    position: absolute;
    transform: translateY(-50%);
    width: 33.33%;
    top: 50%;
    margin-top: 0;

    &:first-of-type {
      left: 0;
      right: auto;
    }
    &:last-of-type {
      left: auto;
    }
  }

  img {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }

  @media (min-width: ${mq.sm}) {
    img {
      position: static;
      transform: none;
      left: auto;
      top: auto;
      width: 100%;
    }
  }

  @media (min-width: ${mq.md}) {
    img {
      width: 50%;
    }
  }

  @media (min-width: ${mq.xl}) {
    img {
      width: 60%;
    }
  }
`;

export const ProductIcon = styled(Icon)`
  display: block;
  margin: 0 auto 1rem;
  fill: currentColor;
  height: 60px;
  width: 60px;

  @media (min-width: ${mq.mm}) {
    margin-bottom: 1.5rem;
    height: 75px;
    width: 75px;
  }
  @media (min-width: ${mq.lg}) {
    height: 100px;
    width: 100px;
  }
  @media (min-width: ${mq.xl}) {
    margin-bottom: 2.5rem;
    height: 125px;
    width: 125px;
  }
`;

export const ProductInfo = styled('div')`
  position: relative;
  z-index: 2;
  width: 95%;
  padding: 0 25px;
  margin: 0 auto;
  text-transform: uppercase;
  overflow: hidden;

  h1 {
    font-size: 1.125rem;
    line-height: 1.5625rem;
    margin: 0.67em 0 6px;
  }

  h2 {
    padding-top: 6px;
    font-size: 0.875rem;
    line-height: 1.09375rem;
    font-weight: 300;
    margin: 0;
  }

  h2,
  p {
    color: #fff;
  }

  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-transform: none;
  }

  @media (min-width: ${mq.sm}) {
    h1,
    h2 {
      font-size: 1.125rem;
      line-height: 1.5625rem;
      font-weight: 700;
      padding: 0;
    }
    p {
      font-size: 0.875rem;
      line-height: 1.3125rem;
      padding: 0;
      overflow: scroll;
    }
  }

  @media (min-width: ${mq.mm}) {
    width: 100%;

    p {
      padding: 0 100px;
    }
  }

  @media (min-width: ${mq.md}) {
    margin: 0 auto;
    width: 33.33%;
    padding: 0;

    h1 {
      font-size: 1.875rem;
      line-height: 2.34375rem;
    }

    h2 {
      margin: 0;
      font-size: 0.875rem;
      line-height: 1.875rem;
    }

    p {
      padding: 0;
    }
  }
`;

export const OverlayCloseTrigger = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const OverlaySlide = styled('div')`
  position: relative;
  text-align: center;
  color: ${(props) => (props.color ? props.color : '#fff')};
  z-index: 11;
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;

  @media (min-width: ${mq.lg}) {
    padding: 5px 0;
  }

  @media (min-width: ${mq.xl}) {
    padding: 40px 0;
  }
`;
