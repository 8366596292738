import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import styled from 'react-emotion';
import { Section, BaseButton, VideoBgWrapper } from '../../components/Styled';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import { getDurationPercentage } from '../../components/VideoPlayer/videoUtil';
import Icon from '../../components/Icon';

import { mq, VIDEOS_PATH } from '../../config';
import VideoPoster from '../../assets/img/trailer/poster.jpg';

const TrailerSection = styled(Section)`
  background-color: #1b1b1b;
  min-height: auto;
  height: 250px;

  @media (min-width: ${mq.mm}) {
    min-height: 100vh;
    height: auto;
  }

  .trailer__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`;

const TrailerControls = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  max-width: 1280px;
  height: 50px;
  bottom: 10%;
  z-index: 1;
  opacity: 0.25;
  transition: opacity 300ms ease;
  display: none;

  &:hover {
    opacity: 1;
  }

  button {
    position: absolute;
    color: #fff;
  }

  .play-pause {
    left: 0;
    bottom: 0;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .audio {
    right: 0;
    bottom: 0;

    svg {
      width: 17.5px;
      height: 17px;
    }
  }

  @media (min-width: ${mq.mm}) {
    display: block;
  }

  @media (max-width: 1280px) {
    padding: 25px;
    max-width: 960px;
    bottom: 15%;
    .play-pause {
      bottom: -25px;
      left: 25px;
    }
    .audio {
      bottom: -25px;
      right: 25px;
    }
  }
`;

const SeekBar = styled('div')`
  width: 100%;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;

  > .duration__played {
    width: 0%;
    height: 5px;
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

class Trailer extends React.Component {
  handleVisibilityChange = async (isVisible, videoEl) => {
    if (!videoEl) return;

    try {
      if (isVisible && videoEl.paused) {
        await videoEl.play();
      } else if (!videoEl.paused) {
        await videoEl.pause();
      }
    } catch (e) {
      console.log(
        "Couldn't auto-play the video because of browser restrictions"
      );
    }
  };
  render() {
    return (
      <TrailerSection className="section trailer">
        <VideoPlayer
          render={({ videoEl, videoState, toggleAudio, togglePlayback }) => {
            return (
              <VisibilitySensor
                minTopValue={300}
                partialVisibility={true}
                onChange={(isVisible) => {
                  this.handleVisibilityChange(isVisible, videoEl);
                }}
              >
                <VideoBgWrapper>
                  <video
                    preload="true"
                    playsInline
                    className="trailer__video"
                    poster={VideoPoster}
                    onClick={togglePlayback}
                  >
                    <source
                      src={VIDEOS_PATH + 'trailer.mp4'}
                      type="video/mp4"
                      media="screen and (min-device-width:769px)"
                    />
                    <source
                      src={VIDEOS_PATH + 'trailer-mobile.mp4'}
                      type="video/mp4"
                      media="screen and (max-device-width:768px)"
                    />
                  </video>
                  <TrailerControls className="trailer__controls">
                    <SeekBar>
                      <div
                        className="duration__played"
                        style={{
                          width: `${getDurationPercentage(videoState)}%`
                        }}
                      />
                    </SeekBar>
                    <BaseButton onClick={toggleAudio} className="audio">
                      <Icon name="video-audio" />
                    </BaseButton>
                    <BaseButton onClick={togglePlayback} className="play-pause">
                      <Icon
                        name={videoState.paused ? 'video-play' : 'video-pause'}
                      />
                    </BaseButton>
                  </TrailerControls>
                </VideoBgWrapper>
              </VisibilitySensor>
            );
          }}
        />
      </TrailerSection>
    );
  }
}

export default Trailer;
