import React from 'react';
import cc from 'classcat';
import { Translate } from 'react-localize-redux';
import products from './productsData';
import { Button } from '../../components/Styled';
import {
  ProductCol,
  ProductsGrid,
  ProductInfo,
  ProductIcon,
  ProductImage,
  ProductDetails,
  ProductsContent,
  ProductsSection,
  sliderArrowsStyles
} from './productsRangeStyles';
import ProductsSliderController from './ProductsSliderController';
import ProductOverlay from './ProductOverlay';
import Slider from '../../components/Slider/Slider';

const Product = ({
  product,
  prevProduct,
  nextProduct,
  isSlide,
  productIndex,
  slidesState,
  setSlideIndex,
  openOverlay
}) => {
  const handleClick = () => {
    if (slidesState.activeIndex !== productIndex) {
      setSlideIndex(productIndex);
    }
    openOverlay();
  };

  const renderContent = (data) => (
    <div key={data.slug}>
      <ProductImage
        onClick={handleClick}
        src={data.img}
        className="product__image"
        alt={data.imgAlt}
      />

      <ProductDetails className="product__detail">
        <ProductIcon name={`tequila-${data.slug}`} />
        <ProductInfo className="product__info">
          <h1>
            <Translate id={`product.${data.slug}.name`} />
          </h1>
          <h2>
            <Translate id={`product.${data.slug}.nickname`} />
          </h2>
          <Button
            onClick={handleClick}
            className="btn btn--primary btn--active"
          >
            Read more
          </Button>
        </ProductInfo>
      </ProductDetails>
    </div>
  );

  const productClassName = cc([
    {
      active: productIndex === slidesState.activeIndex
    },
    `product product--${product.slug}`
  ]);

  if (isSlide) {
    return (
      <ProductCol className={productClassName} color={product.color}>
        <div className="slide--prev">
          {renderContent(prevProduct)}
        </div>
        {renderContent(product)}
        <div className="slide--next">
          {renderContent(nextProduct)}
        </div>
      </ProductCol>
    );
  }

  return (
    <ProductCol className={cc(productClassName)} color={product.color}>
      {renderContent(product)}
    </ProductCol>
  );
};

class ProductsRange extends React.Component {
  state = {
    modalIsOpen: false
  };

  openOverlay = () => {
    this.setState({ modalIsOpen: true });
  };

  closeOverlay = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    return (
      <ProductsSection className="section products with-product-bg">
        <ProductsContent className="products__content p-center">
          <ProductsSliderController
            slidesCount={products.length}
            render={({ state: slidesState, setSlideIndex }) => (
              <React.Fragment>
                <Slider
                  showArrows={true}
                  showNav={false}
                  className="is-hidden-lg"
                  selectedIndex={slidesState.activeIndex}
                  arrowsClassName={cc([sliderArrowsStyles, 'slider__arrows--upper'])}
                  onSlideIndexUpdate={setSlideIndex}
                >
                  {products.map((product, index) => (
                    <Product
                      key={index}
                      product={product}
                      prevProduct={products[slidesState.prevIndex]}
                      nextProduct={products[slidesState.nextIndex]}
                      productIndex={index}
                      slidesState={slidesState}
                      setSlideIndex={setSlideIndex}
                      openOverlay={this.openOverlay}
                      isSlide={true}
                    />
                  ))}
                </Slider>
                <ProductsGrid className="products__grid is-hidden-mobile">
                  {products.map((product, index) => (
                    <Product
                      key={index}
                      product={product}
                      productIndex={index}
                      slidesState={slidesState}
                      setSlideIndex={setSlideIndex}
                      openOverlay={this.openOverlay}
                    />
                  ))}
                </ProductsGrid>
                <ProductOverlay
                  products={products}
                  activeIndex={slidesState.activeIndex}
                  onIndexUpdate={setSlideIndex}
                  onClose={this.closeOverlay}
                  isOpen={this.state.modalIsOpen}
                />
              </React.Fragment>
            )}
          />
        </ProductsContent>
      </ProductsSection>
    );
  }
}

export default ProductsRange;
