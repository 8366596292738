const ASSETS_PATH = `${process.env.PUBLIC_URL}/assets/`;
export const VIDEOS_PATH = ASSETS_PATH + 'videos/';

export const mq = {
  xs: '320px',
  sm: '375px',
  mm: '670px',
  md: '768px',
  lg: '960px',
  xl: '1280px'
};
