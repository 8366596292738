import React from 'react';
import PropTypes from 'prop-types';

class ProductsSliderController extends React.Component {
  state = {
    activeIndex: null,
    prevIndex: null,
    nextIndex: null
  };

  componentWillMount() {
    this.updateSlide(0);
  }

  updateSlide = (index) => {
    const lastSlideIndex = this.props.slidesCount - 1;
    let activeIndex = index;

    if (index < 0) {
      activeIndex = lastSlideIndex;
    } else if (index > lastSlideIndex) {
      activeIndex = 0;
    }

    let nextIndex = activeIndex + 1,
      prevIndex = activeIndex - 1;

    // Check the siblings' bounds
    if (activeIndex === lastSlideIndex) {
      nextIndex = 0;
    }
    if (activeIndex === 0) {
      prevIndex = lastSlideIndex;
    }

    this.setState({ activeIndex, nextIndex, prevIndex });
  };

  onNextSlide = () => {
    this.updateSlide(this.state.activeIndex + 1);
  };

  onPrevSlide = () => {
    this.updateSlide(this.state.activeIndex - 1);
  };

  render() {
    return this.props.render({
      state: this.state,
      onNext: this.onNextSlide,
      onPrev: this.onPrevSlide,
      setSlideIndex: this.updateSlide
    });
  }
}

ProductsSliderController.propTypes = {
  slidesCount: PropTypes.number.isRequired,
  render: PropTypes.func.isRequired
};

export default ProductsSliderController;
