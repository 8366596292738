import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

const FlexibleLink = ({
  to,
  containerPath,
  location,
  className,
  activeClass,
  children,
  onClick,
  offset
}) => {
  if (location.pathname === containerPath) {
    return (
      <ScrollLink
        onClick={onClick}
        activeClass={activeClass}
        className={className}
        to={to}
        spy={true}
        smooth={true}
        offset={offset}
      >
        {children}
      </ScrollLink>
    );
  }

  return (
    <RouterLink
      onClick={onClick}
      className={className}
      to={{
        pathname: containerPath,
        state: {
          scrollEl: to
        }
      }}
    >
      {children}
    </RouterLink>
  );
};

export default withRouter(FlexibleLink);
