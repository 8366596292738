import React from 'react';
import styled from 'react-emotion';
import { withLocalize } from 'react-localize-redux';
import { Element } from 'react-scroll';
import { headlineGradient, responsiveHeadline } from '../components/Styled';
import { mq } from '../config';
import AboutBackground from '../assets/img/global/about.jpg';
import legalTranslations from '../locale/legal';

const LegalContainer = styled('main')`
  background-size: cover;
  background-position: center;
  background-image: url(${AboutBackground});
  background-attachment: fixed;
  overflow: hidden;

  .legal__inner {
    margin: 0 auto 4rem;
    max-width: 620px;
    text-align: center;
    color: #fff;
    padding: 25px;

    @media (min-width: ${mq.mm}) {
      padding: 50px 25px;
    }
  }
  h4 {
    margin: 1.5rem 0;
  }
  p {
    font-size: 0.8rem;
    line-height: 1.875rem;
  }
`;

const Legal = ({ activeLanguage }) => {
  if (!activeLanguage) {
    return null;
  }
  const data = legalTranslations[activeLanguage.code];

  return (
    <LegalContainer className="legal header-offset">
      <div className="legal__inner">
        <div>
          <header className={`${headlineGradient} ${responsiveHeadline}`}>{data.title}</header>
        </div>
        <div className="legal__content">
          <div>{data.legalNotice}</div>
          <Element name="privacyPolicy">{data.privacyPolicy}</Element>
        </div>
      </div>
    </LegalContainer>
  );
};

export default withLocalize(Legal);
