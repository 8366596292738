import styled from 'react-emotion';
import { mq } from '../../config';

export const SliderWrapper = styled('div')`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SliderContainer = styled('div')`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

export const SliderInner = styled('div')`
  overflow: hidden;
  margin: 0;
  touch-action: manipulation;
`;

export const SlidesList = styled('div')`
  display: flex;
  transition: transform 700ms;

  > * {
    flex-basis: 0;
    flex-grow: 1;
  }

  img {
    max-width: 100%;
  }
`;

export const SliderArrow = styled('button')`
  background: none;
  border: none;
  outline: none;
  padding: 0;
  height: 60px;
  width: 21px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  cursor: pointer;
  padding: 100px 25px;

  &::after, &::before {
    content: '';
    display: block;
    position: absolute;
    width: 35px;
    height: 1px; 
    background: #fff;
  }
  
  &.slider__arrow--left {
    left: 15px;
    &::before, &::after {
      left: 15px;
      transform-origin: 0% 50%;
    }
    &::before {
      transform: translate(0, -1px) rotate(55deg);
    }
    &::after {
      transform: translate(0, -1px) rotate(-55deg);
    }
  }

  &.slider__arrow--right {
    right: 15px;
    &::before, &::after {
      right: 15px;
      transform-origin: 100% 50%;
    }
    &::before {
      transform: translate(0, -1px) rotate(-55deg);
    }
    &::after {
      transform: translate(0, -1px) rotate(55deg);
    }
  }
`;

export const SliderFooter = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5em;
`;

export const SliderNavDot = styled('button')`
  display: inline-block;
  height: 8px;
  width: 8px;
  background-color: rgba(39, 176, 212, 0.2);
  border-radius: 50%;
  margin: 0 5px;
  border: 0;
  outline: 0;
  cursor: pointer;

  @media (min-width: ${mq.lg}) {
    height: 14px;
    width: 14px;
    margin-left: 7px;
    margin-right: 7px;
  }

  &.slider__dot--active {
    background-color: #f26521;
  }
`;