import styled from 'react-emotion';
import { Section } from '../../components/Styled';
import { mq } from '../../config';

export const DrinksSection = styled(Section)`
  display: flex;
  flex-direction: column;

  .drink__container {
    position: relative;
    flex-grow: 1;
    height: 100%;
    padding-bottom: 2rem;
    display: flex;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.5) 10%,
      rgba(0, 0, 0, 0.45) 100%
    );

    @media (min-width: ${mq.md}) {
      background: none;
    }
  }

  .drink__content {
    max-width: 1230px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @media (min-width: ${mq.md}) {
      margin-bottom: 3rem;
    }

    .drink__details {
      flex-grow: 1;
    }
  }
`;

export const DrinkBgImage = styled('div')`
  position: absolute;
  bottom: 100px;
  left: 0;
  height: 60%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: contain;
  pointer-events: none;
  display: none;

  @media (min-width: ${mq.md}) {
    bottom: 50px;
    left: 5%;
    width: 60%;
    display: block;
  }
`;

export const DrinkImage = styled('div')`
  margin: 2rem auto;
  padding: 0 1rem;
  max-width: 600px;
  @media (min-width: ${mq.md}) {
    display: none;
  }
`;

export const DrinkName = styled('header')`
  margin: 5vh 0 1rem;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  text-transform: uppercase;
  text-align: center;

  @media (min-width: ${mq.md}) {
    font-size: 1.875rem;
    line-height: 2.34375rem;
  }
`;

export const DrinkInfo = styled('div')`
  margin: 0 auto;
  padding: 0 25px;
  width: 95%;
  color: #fff;
  overflow: auto;

  p {
    margin: 1.25rem 0;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  @media (min-width: ${mq.md}) {
    width: 33%;

    p {
      font-size: 1.125rem;
      line-height: 1.5625rem;
    }
  }
`;

export const DrinkDetails = styled('div')`
  position: relative;
  overflow: hidden;
  padding: 0 5% 30px;
  text-shadow: 1px 1px 3px #000;

  &::before {
    content: '';
    display: table;
  }

  p {
    position: relative;
    margin: 3px 0 0;
    &::after {
      content: attr(data-amount);
      position: absolute;
      display: block;
      top: 0;
    }
  }

  h4 {
    text-transform: uppercase;
    font-weight: 300;
    margin: 15px 0 5px;
  }

  p,
  h4 {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .yellow,
  p::after {
    color: #d8b423;
  }

  > .recipe__col {
    width: 100%;
    text-align: right;
    padding-right: 105px;
    p::after {
      right: -20px;
      transform: translateX(100%);
    }
  }

  @media (min-width: ${mq.md}) {
    padding: 0 15% 50px;

    p,
    h4 {
      font-size: 1.125rem;
      line-height: 1.5625rem;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 2px;
      height: 90%;
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        #ffffff 50%,
        rgba(255, 255, 255, 0)
      );
    }
    > .recipe__col {
      width: 50%;
    }
    > .recipe__col:nth-child(1) {
      text-align: right;
      padding-right: 20px;
      p::after {
        left: auto;
        right: -40px;
        transform: translateX(100%);
      }
    }
    > .recipe__col:nth-child(2),
    > .recipe__col.recipe__col--right {
      text-align: left;
      padding-left: 20px;
      p::after {
        left: -40px;
        right: auto;
        transform: translateX(-100%);
      }
    }
    /* Column styles */
    > .recipe__col:nth-child(1n) {
      float: left;
      margin-right: 50%;
      clear: none;
    }
    > .recipe__col:last-child {
      margin-right: 0;
    }
    > .recipe__col:nth-child(2n),
    > .recipe__col.recipe__col--right {
      margin-right: 0;
      float: right;
    }
    > .recipe__col:nth-child(2n + 1),
    > .recipe__col.recipe__col--right {
      clear: left;
    }
  }
`;
