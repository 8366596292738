import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import cc from 'classcat';
import FlexibleLink from '../FlexibleLink';
import LanguageToggle from '../LanguageToggle';
import { buttonStyles } from '../Styled';
import { HeaderContainer } from './headerStyles';
import LogoImg from '../../assets/img/global/logo.png';
import './header.css';

const LINKS = [
  {
    slug: 'trailer'
  },
  {
    slug: 'products'
  },
  {
    slug: 'drinks'
  },
  {
    slug: 'logo'
  },
  {
    slug: 'process'
  },
  {
    slug: 'story'
  }
];

class Header extends Component {
  state = {
    open: false
  };

  toggleMenu = () => {
    this.setState({ open: !this.state.open });
  };

  closeMenu = () => {
    this.setState({ open: false });
  };

  render() {
    const LogoLink = (
      <FlexibleLink onClick={this.closeMenu} to="intro" containerPath="/">
        <img src={LogoImg} alt="Sierra Milenario" />
      </FlexibleLink>
    );

    return (
      <HeaderContainer className="header">
        <div className="header__inner">
          <div className="mobileLogo mobile">{LogoLink}</div>
          <div
            onClick={this.toggleMenu}
            className={cc([
              'navBtn mobile',
              {
                open: this.state.open
              }
            ])}
          >
            <span />
            <span />
            <span />
          </div>
          <nav className={cc(['header__nav', { open: this.state.open }])}>
            {LINKS.map(
              (link) =>
                link.slug === 'logo' ? (
                  <div key={link.slug} className="logo">
                    {LogoLink}
                  </div>
                ) : (
                  <div key={link.slug}>
                    <FlexibleLink
                      to={link.slug}
                      onClick={this.closeMenu}
                      activeClass="btn--active"
                      className={`btn btn--primary ${buttonStyles}`}
                      containerPath="/"
                    >
                      <Translate id={`header.link.${link.slug}`} />
                    </FlexibleLink>
                  </div>
                )
            )}
            <div>
              <LanguageToggle className="btn btn--primary" />
            </div>
          </nav>
        </div>
      </HeaderContainer>
    );
  }
}

export default Header;
