import React from 'react';
import styled from 'react-emotion';
import { Translate } from 'react-localize-redux';
import { Section, headlineGradient } from '../../components/Styled';

const ProductsSection = styled(Section)`
  .productsIntro__inner {
    top: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    left: 0;
    right: 0;
    position: absolute;
    transition: all 500ms ease;
  }
`;

const ProductsIntroContent = styled('div')`
  max-width: 500px;
  width: 100%;
  text-align: center;
  p {
    font-size: 1.125rem;
    line-height: 1.875rem;
  }
  header {
    margin: 0 auto 1.5rem;
    font-size: 1.875rem;
    line-height: 2.34375rem;
  }
  @media screen and (max-width: 320px) {
    header {
      margin-bottom: 25px;
    }
    p {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
`;

const ProductsIntro = () => {
  return (
    <ProductsSection className="section productsIntro">
      <div className="productsIntro__inner flex-center with-product-bg">
        <ProductsIntroContent className="productsIntro__content">
          <header className={headlineGradient}>
            <Translate id="products.header" />
          </header>
          <p>
            <Translate id="products.copy" />
          </p>
        </ProductsIntroContent>
      </div>
    </ProductsSection>
  );
};

export default ProductsIntro;
