import blancoImg from '../../assets/img/global/blanco.png';
import reposadoImg from '../../assets/img/global/reposado.png';
import anejoImg from '../../assets/img/global/anejo.png';
import extraAnejoImg from '../../assets/img/global/extra-anejo.png';
import cafeImg from '../../assets/img/global/cafe.png';
import fumadoImg from '../../assets/img/global/fumado.png';

import blancoBackImg from '../../assets/img/global/blanco-back.png';
import reposadoBackImg from '../../assets/img/global/reposado-back.png';
import anejoBackImg from '../../assets/img/global/anejo-back.png';
import extraAnejoBackImg from '../../assets/img/global/extra-anejo-back.png';
import cafeBackImg from '../../assets/img/global/cafe-back.png';
import fumadoBackImg from '../../assets/img/global/fumado-back.png';

export default [
  {
    imgAlt: 'Sierra Milenario Blanco',
    img: blancoImg,
    imgBack: blancoBackImg,
    slug: 'blanco',
    color: '#1b9db5'
  },
  {
    imgAlt: 'Sierra Milenario Reposado',
    img: reposadoImg,
    imgBack: reposadoBackImg,
    slug: 'reposado',
    color: '#cc0e2e'
  },
  {
    imgAlt: 'Sierra Milenario Anejo',
    img: anejoImg,
    imgBack: anejoBackImg,
    slug: 'anejo',
    color: '#6ac1a2'
  },
  {
    imgAlt: 'Sierra Milenario Extra Anejo',
    img: extraAnejoImg,
    imgBack: extraAnejoBackImg,
    slug: 'extra-anejo',
    color: '#926d24'
  },
  {
    imgAlt: 'Sierra Milenario Cafe',
    img: cafeImg,
    imgBack: cafeBackImg,
    slug: 'cafe',
    color: '#ec5e10'
  },
  {
    imgAlt: 'Sierra Milenario Fumado',
    img: fumadoImg,
    imgBack: fumadoBackImg,
    slug: 'fumado',
    color: '#839589'
  }
]
