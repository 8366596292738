import styled, { css } from 'react-emotion';
import { mq } from '../config';

export const Section = styled('section')`
  width: 100%;
  min-height: ${(props) => (props.minHeight ? props.minHeight : '100vh')};
  position: relative;
  color: #fff;
`;

export const FixedWrapper = styled('div')`
  width: 100%;
  height: 100%;
  max-width: 1230px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const NarrowContent = styled('div')`
  margin: 0 auto;
  max-width: 496px;
  text-align: center;
  padding: 15px;

  @media (min-width: ${mq.sm}) {
    padding: 0;
  }
`;

export const SectionScroll = styled('div')`
  margin: auto;
  position: absolute !important;
  left: 0;
  right: 0;
  text-transform: uppercase;
  font-size: 1rem;
  text-align: center;
  z-index: 12;
  bottom: 35px;

  @media (min-width: ${mq.lg}) {
    font-size: 1.15rem;
  }
`;

export const buttonStyles = css`
  background: 0;
  outline: 0;
  border: 0;
  cursor: pointer;
  padding: 8.5px 12.5px;
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  text-decoration: none;

  &.btn--primary {
    border-bottom: 1px solid rgba(215, 180, 34, 0);
    transition: color 300ms ease;
    color: rgba(255, 255, 255, 0.6);

    &:hover {
      color: #fff;
    }
    &.active {
      color: #fff;
    }

    &::before {
      left: -1px;
    }
    &::after {
      right: -1px;
    }
    &::before,
    &::after {
      content: '';
      position: absolute;
      background-image: linear-gradient(rgba(215, 180, 34, 0), #d7b422);
      top: -1px;
      bottom: -1px;
      width: 1px;
      opacity: 0;
    }
    &:hover,
    &.active,
    &.btn--active {
      border-color: #d7b422;
    }
    &:hover::before,
    &:hover::after,
    &.active::before,
    &.active::after,
    &.btn--active::before,
    &.btn--active::after {
      opacity: 1;
    }
  }

  &.btn--active {
    color: #fff;
    &:hover {
      color: #d7b422;
    }
  }

  &.btn--large {
    min-width: 100px;
  }
`;

export const Button = styled('button')(buttonStyles);

export const VideoBgWrapper = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  video {
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    z-index: 0;
    transform: translate3d(-50%, -50%, 0);
  }
`;

export const headlineGradient = css`
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  padding-bottom: 1rem;

  @media (min-width: ${mq.sm}) {
    padding-bottom: 1.5rem;
  }

  &::after {
    content: '';
    width: 100%;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(
      to right,
      rgba(255, 0, 0, 0) 0%,
      #d7b422 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`;

export const responsiveHeadline = css`
  font-size: 1.125rem;
  line-height: 1.5625rem;

  @media (min-width: ${mq.sm}) {
    font-size: 1.75rem;
    line-height: 2.34375rem;
  }
`;

export const BaseButton = styled('button')`
  border: 0;
  outline: 0;
  background-color: transparent;
  appearance: none;
  cursor: pointer;
`;

export const GradientBar = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  transform: translateX(-50%);
  height: 100%;
  width: 2px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    white 30%,
    white 70%,
    rgba(255, 255, 255, 0) 100%
  );
`;
