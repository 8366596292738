import { injectGlobal } from 'react-emotion';
import ProductBackground from './assets/img/global/product-background.jpg';
import { mq } from './config';

const sectionGradient = `
  hsl(0, 0%, 0%) 0%,
  hsla(0, 0%, 0%, 0.987) 0.3%,
  hsla(0, 0%, 0%, 0.951) 1.1%,
  hsla(0, 0%, 0%, 0.896) 2.5%,
  hsla(0, 0%, 0%, 0.825) 4.7%,
  hsla(0, 0%, 0%, 0.741) 7.7%,
  hsla(0, 0%, 0%, 0.648) 11.6%,
  hsla(0, 0%, 0%, 0.55) 16.4%,
  hsla(0, 0%, 0%, 0.45) 22.3%,
  hsla(0, 0%, 0%, 0.352) 29.4%,
  hsla(0, 0%, 0%, 0.259) 37.6%,
  hsla(0, 0%, 0%, 0.175) 47.2%,
  hsla(0, 0%, 0%, 0.104) 58.1%,
  hsla(0, 0%, 0%, 0.049) 70.5%,
  hsla(0, 0%, 0%, 0.013) 84.4%,
  hsla(0, 0%, 0%, 0) 100%
`;

injectGlobal`
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    font-family: "jost", sans-serif;
    font-weight: 300;
    background-color: #000;
    color: #fff;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  /* Utilities */
  
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .p-center {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    margin: auto;
  }

  .block-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .text-center {
    text-align: center;
  }

  .with-product-bg {
    background-color: #1b1b1b;
    background-image: url(${ProductBackground});
    background-size: cover;
    background-position: center;
  }

  .is-hidden-lg {
    @media (min-width: ${mq.lg}) {
      display: none !important;
    }
  }

  .is-hidden-mobile {
    @media (max-width: 959px) {
      display: none !important;
    }
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }

  .header-offset {
    padding-top: 100px;
  }

  .top-gradient::before, .bottom-gradient::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 30vh;
    max-height: 200px;
    pointer-events: none;
  }

  .top-gradient::before {
    top: 0;
    background-image: linear-gradient(
      to bottom,
      ${sectionGradient}
    );
  }

  .bottom-gradient::after {
    bottom: 0;
    background-image: linear-gradient(
      to top,
      ${sectionGradient}
    );
  }
`;
