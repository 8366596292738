export default (short, availableLangs) => {
  const nav = window.navigator;
  let lang =
    (nav.languages && nav.languages[0]) ||
    nav.language ||
    nav.browserLanguage ||
    nav.userLanguage;

  lang = lang && short ? lang.substr(0, 2) : lang;
  
  if (availableLangs && availableLangs.length > 0) {
    return availableLangs.indexOf(lang) > -1 ? lang : null;
  }

  return lang;
};
