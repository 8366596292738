import styled from 'react-emotion';
import { Section } from '../../components/Styled';
import AboutBackground from '../../assets/img/global/about.jpg';
import { mq } from '../../config';

export const AboutSection = styled(Section)`
  background-size: cover;
  background-position: center;
  background-image: url(${AboutBackground});
  background-attachment: fixed;
  padding: 8.5rem 0 5rem;

  @media (min-width: ${mq.lg}) {
    padding: 8.5rem 0;
  }
`;

export const AboutPart = styled('div')`
  margin-top: 2rem;
  h4 {
    margin: 1rem 0;
    opacity: 0.5;
    font-size: 0.875rem;
    line-height: 1.09375rem;
    text-transform: uppercase;
    font-weight: 300;
  }

  p,
  a {
    font-size: 0.75rem;
    line-height: 1.125rem;
    font-weight: bolder;

    @media (min-width: ${mq.sm}) {
      line-height: 1.25rem;
    }
  }
  p {
    margin: 0;
  }
  .about__link {
    margin-top: 0.5rem;
    display: block;
  }
  .btn {
    margin-top: 1.5rem;
  }
`;

export const AboutRow = styled('div')`
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 2rem 0;

  @media (min-width: ${mq.sm}) {
    margin: 4rem 0;
  }

  > .about__col {
    width: 50%;
    &:nth-of-type(1) {
      padding-right: 5px;
    }
    &:nth-of-type(2) {
      padding-left: 5px;
    }
  }
  .about__row--separator {
    left: 50%;
    transform: translateX(-50%);
  }
`;
