import React, { Fragment } from 'react';

export default {
  en: [
    {
      name: 'THE SNAKE MARGARITA',
      description:
        'Creativity is to take a well-established drink and twist\nit to something unexpectedly awesome.',
      recipe: (
        <Fragment>
          <div className="recipe__col">
            <h4>Ingredients</h4>
            <p data-amount="50 ml">Sierra Milenario Tequila Blanco</p>
            <p data-amount="20 ml">
              Freshly squeezed margarita citrus mix
              <span className="yellow">*</span>
            </p>
            <p data-amount="20 ml">Elderflower syrup</p>
            <p>A pinch of lemon salt</p>
            <h4>Directions</h4>
            <p>
              Add all ingredients. Shake well and strain into a tumbler with ice
              cubes. Garnish with a twist of lemon peel.
            </p>
          </div>
          <div className="recipe__col">
            <h4>
              <span className="yellow">*</span>
              FRESHLY SQUEEZED MARGARITA CITRUS MIX
            </h4>
            <h4>Ingredients</h4>
            <p data-amount="850 ml">Freshly squeezed lime juice</p>
            <p data-amount="150 ml">Freshly squeezed lemon juice</p>
            <h4>Directions</h4>
            <p>Combine the freshly squeezed juices fill into a bottle.</p>
          </div>
        </Fragment>
      )
    },
    {
      name: 'THE JAGUAR',
      description: 'One sip is all it takes and you intuitively know…',
      recipe: (
        <Fragment>
          <div className="recipe__col">
            <h4>Ingredients</h4>
            <p data-amount="50 ml">Sierra Milenario Tequila Reposado</p>
            <p data-amount="20 ml">Freshly squeezed margarita citrus mix</p>
            <p data-amount="20 ml">
              Agua de Jamaica sweet
              <span className="yellow">*</span>
            </p>
            <p>A pinch of natural rock salt</p>
            <h4>Directions</h4>
            <p>
              Take a cocktail shaker filled to the brim with ice cubes. Pour all
              ingredients into the shaker. Shake well and strain into a rock
              glass filled with ice cubes. Garnish with a piece of pink
              grapefruit.
            </p>
          </div>
          <div className="recipe__col">
            <h4>
              <span className="yellow">*</span>
              AGUA DE JAMAICA SWEET
            </h4>
            <h4>Ingredients</h4>
            <p data-amount="100 g">Dried hibiscus flowers</p>
            <p data-amount="1000 ml">Water</p>
            <p data-amount="1000 ml">Agave syrup</p>
            <p data-amount="15 ml">Strained lime juice</p>
            <p>A few thin slices of ginger</p>
            <h4>Directions</h4>
            <p>
              Put the water and the agave syrup into a medium sized cooking pot
              and add some ginger slices. Gently heat it up until the agave
              syrup has dissolved and remove it from the stove. Add the dried
              hibiscus flowers, cover and let sit for approx 20 minutes. Strain
              into a pitcher and discard the used hibiscus flowers and the
              ginger leftovers. Add the lime juice to taste and stir.
            </p>
          </div>
        </Fragment>
      )
    },
    {
      name: 'THE MONKEY RAC',
      description:
        'One may take: a little craziness, a little tropical\nfruitiness et voilà, a drink entertaining like a jungle\ndrum.',
      recipe: (
        <Fragment>
          <div className="recipe__col">
            <h4>Ingredients</h4>
            <p data-amount="60 ml">Sierra Milenario Tequila Añejo</p>
            <p data-amount="1 barspoon">
              Homemade pineapple syrup
              <span className="yellow">*</span>
            </p>
            <p>A pinch of chili salt</p>
            <p data-amount="3 drops">Peychaud´s cocktail bitters</p>
            <p>A touch of Chartreuse Jaune</p>
            <h4>Directions</h4>
            <p>
              Take a chilled tumbler and spray it with a touch of Chartreuse.
              Then, fill the tumbler with ice cubes and strain the cocktail. Add
              a slice of fresh or dried mango if you can’t get hold of ripe
              mangoes.
            </p>
          </div>
          <div className="recipe__col">
            <h4>
              <span className="yellow">*</span>
              HOMEMADE PINEAPPLE SYRUP
            </h4>
            <h4>Ingredients</h4>
            <p data-amount="1 cup">Fresh pineapple juice</p>
            <p>(Blend the pineapple until smooth and strain through a cloth)</p>
            <p data-amount="1 cup">Sugar</p>
            <h4>Directions</h4>
            <p>
              Boil the pineapple juice together with the sugar for approx. 2
              minutes. Remove the liquid from the stove, pour it into a sealed
              glass jar and keep it chilled. The syrup will thicken a little as
              it cools.
            </p>
          </div>
        </Fragment>
      )
    },
    {
      name: 'THE OLD EAGLE',
      description: 'Old, but never out of fashion. Cheers to this wisdom!',
      recipe: (
        <Fragment>
          <div className="recipe__col recipe__col--right">
            <h4>Ingredients</h4>
            <p data-amount="60 ml">Sierra Milenario Tequila Extra-Añejo</p>
            <p data-amount="2 barspoon">
              Agave water (50% agave syrup & 50% water)
            </p>
            <p data-amount="2 drops">Angostura aromatic bitters</p>
            <p data-amount="2 drops">Chocolate bitters</p>
            <p>A pinch of salt &amp; black pepper</p>
            <h4>Directions</h4>
            <p>
              Fill a mixing glass with the agave water and bitters. Stir for 20
              seconds on ice cubes. Add the tequila, salt and pepper and stir
              again. Strain into a tumbler filled with ice cubes. Garnish with
              maraschino cherries and shavings of dark, high-quality chocolate.
            </p>
          </div>
        </Fragment>
      )
    },
    {
      name: 'SMOKED BANDERA',
      description:
        'Don’t play with fire. At least this is what people\nrecommend. We say, why not?',
      recipe: (
        <Fragment>
          <div className="recipe__col">
            <h4>Ingredients</h4>
            <p data-amount="30 ml">Sierra Milenario Tequila Fumado</p>
            <p data-amount="30 ml">Freshly squeezed lime juice</p>
            <p data-amount="30 ml">
              Homemade sangrita
              <span className="yellow">*</span>
            </p>
            <h4>Directions</h4>
            <p>
              Put each of the ingredients in a separate shotglas. First drink
              the lime, then tequila and finish with sangrita. The Bandera is
              the Mexican flag whose green, white and red colours are
              represented by the three shots.
            </p>
          </div>
          <div className="recipe__col">
            <h4>
              <span className="yellow">*</span>
              HOMEMADE SANGRITA
            </h4>
            <h4>Ingredients</h4>
            <p data-amount="450 ml">Tomato juice</p>
            <p data-amount="30 ml">Freshly squeezed lime juice</p>
            <p data-amount="210 ml">Orange juice</p>
            <p data-amount="60 ml">Red ruby pink grapefruit juice</p>
            <p data-amount="1 teaspoon">Sea salt</p>
            <p data-amount="2 teaspoon">Freshly ground black pepper</p>
            <p data-amount="1 (cut in half with seeds)">Jalapeno</p>
            <p data-amount="to taste">Habanero sauce</p>
            <h4>Directions</h4>
            <p>
              Combine all the ingredients in a pitcher. Stir until the salt and
              pepper dissolve. Add the Jalapeno and leave in for approx. 15-30
              minutes until spicy. Strain and discard the jalapeno pieces. Store
              in refrigerator.
            </p>
          </div>
        </Fragment>
      )
    },
    {
      name: 'THE MEXICAN RABBIT',
      description:
        'Ready for a true Mexican adventure? Take a little sip of\nthis exciting agave, coffee and vanilla blend and you will\nfind yourself right in the depth of the Mayan jungle.',
      recipe: (
        <Fragment>
          <div className="recipe__col recipe__col--right">
            <h4>Ingredients</h4>
            <p data-amount="60 ml">Sierra Milenario Tequila Café</p>
            <p data-amount="2-3 scoops">Vanilla frozen yoghurt</p>
            <h4>Directions</h4>
            <p>
              Put the frozen yoghurt scoops into a tumbler and add Sierra
              Milenario Café.
            </p>
          </div>
        </Fragment>
      )
    }
  ],
  de: [
    {
      name: 'THE SNAKE MARGARITA',
      description:
        'Kreativität ist, einen gut bekannten Drink zu nehmen,\nund daraus etwas unerwartet großartiges zu machen.',
      recipe: (
        <Fragment>
          <div className="recipe__col">
            <h4>Zutaten</h4>
            <p data-amount="50 ml">Sierra Milenario Tequila Blanco</p>
            <p data-amount="20 ml">
              Frisch gepresster Margarita Zitrus-Mix
              <span className="yellow">*</span>
            </p>
            <p data-amount="20 ml">Holundersirup</p>
            <p>Eine Prise Zitronen-Salz</p>
            <h4>Anleitung</h4>
            <p>
              Alle Zutaten zusammengeben. Gut schütteln und in ein Tumbler-Glas
              über Eiswürfeln abseihen. Mit einer gedrehten Zitronenschale
              garnieren.
            </p>
          </div>
          <div className="recipe__col">
            <h4>
              <span className="yellow">*</span>
              Frisch gepresster Margarita Zitrus-Mix
            </h4>
            <h4>Ingredients</h4>
            <p data-amount="850 ml">Frisch gepresster Limettensaft</p>
            <p data-amount="150 ml">Frisch gepresster Zitronensaft</p>
            <h4>Anleitung</h4>
            <p>
              Kombiniere die frisch gepressten Säfte und fülle sie in eine
              Flasche.
            </p>
          </div>
        </Fragment>
      )
    },
    {
      name: 'THE JAGUAR',
      description:
        'Ein Schluck ist alles was du brauchst,\nund du wirst intuitiv Bescheid wissen…',
      recipe: (
        <Fragment>
          <div className="recipe__col">
            <h4>Zutaten</h4>
            <p data-amount="50 ml">Sierra Milenario Tequila Reposado</p>
            <p data-amount="20 ml">Frisch gepresster Margarita Zitrus-Mix</p>
            <p data-amount="20 ml">
              Hibiskus-Tee (süß)
              <span className="yellow">*</span>
            </p>
            <p>Eine Prise Steinsalz</p>
            <h4>Anleitung</h4>
            <p>
              Nimm einen Cocktail-Shaker und fülle ihn randvoll mit Eiswürfeln.
              Gib alle Zutaten in den Shaker. Schüttle ordentlich und gieße den
              Inhalt in ein rundes, mit Eiswürfeln gefülltes Glas. Garniere mit
              einer Scheibe pinker Grapefruit.
            </p>
          </div>
          <div className="recipe__col">
            <h4>
              <span className="yellow">*</span>
              Hibiskus-Tee (süß)
            </h4>
            <h4>Zutaten</h4>
            <p data-amount="100 g">Getrocknete Hibiskus-Blüten</p>
            <p data-amount="1000 ml">Wasser</p>
            <p data-amount="1000 ml">Agavensirup</p>
            <p data-amount="15 ml">Gesiebter Limettensaft</p>
            <p>Ein paar dünne Scheiben Ingwer</p>
            <h4>Anleitung</h4>
            <p>
              Gib das Wasser und den Agavensirup in einen mittelgroßen Kochtopf
              und tue einige Ingwerscheiben dazu. Erhitze auf kleiner Flamme bis
              der Agavensirup sich aufgelöst hat und nimm den Topf von der
              Kochfläche. Gib die Hibiskus-Blüten dazu, decke den Topf ab und
              lass das Ganze für etwa 20 Minuten ziehen. Durch ein Sieb in einen
              Pitcher gießen und die benutzten Blüten und Ingwer-Reste
              wegnehmen. Gib nach Geschmack Limettensaft dazu und rühre gut
              durch.
            </p>
          </div>
        </Fragment>
      )
    },
    {
      name: 'THE MONKEY RAC',
      description:
        'Man nehme: etwas Verrücktheit, etwas tropische Fruchtigkeit\net voilà, ein Drink, der dich aufmischt wie eine Dschungel-Trommel.',
      recipe: (
        <Fragment>
          <div className="recipe__col">
            <h4>Zutaten</h4>
            <p data-amount="60 ml">Sierra Milenario Tequila Añejo</p>
            <p data-amount="1 Barlöffel">
              Hausgemachter Ananassirup
              <span className="yellow">*</span>
            </p>
            <p>Eine Prise Chili-Salz</p>
            <p data-amount="3 Tropfen">Peychaud’s Bitters</p>
            <p>Etwas Chartreuse Jaune</p>
            <h4>Anleitung</h4>
            <p>
              Nimm ein gekühltes Tumbler-Glas und gib ein paar Tropfen
              Chartreuse Jaune hinein. Fülle den Tumbler dann mit Eiswürfeln und
              seihe den Cocktail ab. Gib eine Scheibe frische oder getrocknete
              Mango hinzu, wenn du nicht an frische Mango kommst.
            </p>
          </div>
          <div className="recipe__col">
            <h4>
              <span className="yellow">*</span>
              Hausgemachter Ananassirup
            </h4>
            <h4>Zutaten</h4>
            <p data-amount="1 Tasse">Frischer Ananassaft</p>
            <p>
              (Gib eine Ananas in einen Mixer und mixe bis es ein Saft geworden
              ist, siebe dann durch ein Geschirrtuch)
            </p>
            <p data-amount="1 Tasse">Zucker</p>
            <h4>Anleitung</h4>
            <p>
              Koche den Ananassaft zusammen mit dem Zucker für etwa 2 Minuten.
              Entferne die Flüssigkeit vom Herd, gieße sie in ein Einmachglas
              mit Deckel, und halte es gekühlt. Der Sirup wird dicker werden mit
              sinkender Temperatur.
            </p>
          </div>
        </Fragment>
      )
    },
    {
      name: 'THE OLD EAGLE',
      description: 'Alt, aber niemals außer Mode. Prost auf seine Weisheit!',
      recipe: (
        <Fragment>
          <div className="recipe__col recipe__col--right">
            <h4>Zutaten</h4>
            <p data-amount="60 ml">Sierra Milenario Tequila Extra-Añejo</p>
            <p data-amount="2 Barlöffel">
              Agavenwasser (50% Agavensirup & 50% Wasser)
            </p>
            <p data-amount="2 Tropfen">Angostura Bitters</p>
            <p data-amount="2 Tropfen">Schokoladen Bitters</p>
            <p>Eine Prise Salz und schwarzer Pfeffer</p>
            <h4>Anleitung</h4>
            <p>
              Fülle einen Mix-Becher mit dem Agavenwasser und Bitters. Rühre für
              20 Sekunden mit Eiswürfeln. Gib den Tequila und das Salz & Pfeffer
              hinzu und rühre nochmals. Seihe in ein mit Eiswürfeln gefülltes
              Tumbler-Glas ab. Garniere mit Maraschino-Kirschen und Spänen
              dunkler, qualitativer Schokolade.
            </p>
          </div>
        </Fragment>
      )
    },
    {
      name: 'SMOKED BANDERA',
      description:
        'Spiele nicht mit dem Feuer. Jedenfalls wird das empfohlen.\nWir sagen: warum nicht?',
      recipe: (
        <Fragment>
          <div className="recipe__col">
            <h4>Zutaten</h4>
            <p data-amount="30 ml">Sierra Milenario Tequila Fumado</p>
            <p data-amount="30 ml">Frisch gepresster Limettensaft</p>
            <p data-amount="30 ml">
              Hausgemachter Sangrita
              <span className="yellow">*</span>
            </p>
            <h4>Anleitung</h4>
            <p>
              Gib alle Zutaten in einzelne Shot-Gläser. Trinke zuerst den
              Limettensaft, dann den Tequila, und zuletzt den Sangrita. Der
              “Bandera” ist die Mexikanische Flagge, dessen Farben Grün, Weiß,
              und Rot die drei Shots repräsentieren.
            </p>
          </div>
          <div className="recipe__col">
            <h4>
              <span className="yellow">*</span>
              Hausgemachter Sangrita
            </h4>
            <h4>Zutaten</h4>
            <p data-amount="450 ml">Tomatensaft</p>
            <p data-amount="30 ml">Frisch gepresster Limettensaft</p>
            <p data-amount="210 ml">Orangensaft</p>
            <p data-amount="60 ml">Pink Grapefruit Saft</p>
            <p data-amount="1 Teelöffel">Meersalz</p>
            <p data-amount="2 Teelöffel">Frisch gemahlener schwarzer Pfeffer</p>
            <p data-amount="1 (halbiert, mit Samen)">Jalapeño</p>
            <p data-amount="nach Geschmack">Habanero-Sauce</p>
            <h4>Anleitung</h4>
            <p>
              Kombiniere alle Zutaten in einem Pitcher. Rühe, bis Salz und
              Pfeffer sich aufgelöst haben. Gib die Jalapeño hinzu und lasse es
              für etwa 15-30 Minuten ziehen, bis es scharf geworden ist. Siebe
              die Flüssigkeit durch und entferne die Jalapeño-Teile. Im
              Kühlschrank aufbewahren.
            </p>
          </div>
        </Fragment>
      )
    },
    {
      name: 'THE MEXICAN RABBIT',
      description:
        'Bereit für ein authentisch Mexikanisches Abenteuer? Nimm einen kleinen Schluck\ndieser aufregenden Agave, des Kaffees, und der Vanille-Mischung,\nund du wirst dich direkt in den Tiefen des Maya-Dschungels wiederfinden.',
      recipe: (
        <Fragment>
          <div className="recipe__col recipe__col--right">
            <h4>Zutaten</h4>
            <p data-amount="60 ml">Sierra Milenario Tequila Café</p>
            <p data-amount="2-3 Kugeln">Frozen Yoghurt Vanille</p>
            <h4>Anleitung</h4>
            <p>
              Gib die Frozen Yoghurt Kugeln in ein Tumbler-Glas und füge den
              Sierra Milenario Café hinzu.
            </p>
          </div>
        </Fragment>
      )
    }
  ]
};
